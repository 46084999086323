import React, { useEffect, forwardRef, useContext } from "react";
import { AppContext } from "../../context/AppContext";

const MediaPlayer = forwardRef((props, ref) => {
  console.log(props.temp);

  const { registerdUsers } = useContext(AppContext);

  useEffect(() => {
    if (!ref || !ref.current || !props.videoTrack) return;

    const videoElement = ref.current;
    props.videoTrack.play(videoElement);

    if (props.onPlayerReady) {
      props.onPlayerReady(videoElement);
    }

    return () => {
      props.videoTrack.stop();
    };
  }, [ref, props.videoTrack, props.onPlayerReady]);

  useEffect(() => {
    if (!props.audioTrack) return;

    props.audioTrack.play();

    return () => {
      props.audioTrack.stop();
    };
  }, [props.audioTrack]);

  const getRemoteUserById = () => {
    if (
      !registerdUsers ||
      registerdUsers.length === 0 ||
      props.remoteUserId == null
    ) {
      const therapistDetails = JSON.parse(
        sessionStorage.getItem("TherapistDetails")
      );

      if (
        therapistDetails &&
        therapistDetails.firstName &&
        therapistDetails.lastName
      ) {
        const fullname =
          therapistDetails.firstName + " " + therapistDetails.lastName;
        return fullname;
      } else {
        return "Instrcutor";
      }
    }

    const uid = props.remoteUserId.substring(4);
    let user = registerdUsers.find((user) => uid === user._id);

    return user?.name || "Instructor";
  };

  return (
    <div
      ref={ref}
      id={props.id}
      className={props.classNames ? props.className : "video-player"}
      style={
        props.style
          ? props.style
          : {
              width: "320px",
              height: "240px",
              position: "relative",
              top: "0",
              left: "0",
            }
      }
    >
      <div
        className={
          props.mainPlayer ? "name-screen main_player" : "name-screen side"
        }
      >
        {props.userName ?? getRemoteUserById()}
      </div>
    </div>
  );
});

export default MediaPlayer;
