/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { Button } from "antd";
import Avatar from "react-avatar-edit";
import { Dialog } from "primereact/dialog";
import * as Ai from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import "./Instructors.css";
import "antd/dist/reset.css";
import Profile from "../../assets/placeholder.png";
import { AppContext } from "../../context/AppContext";
import Header from "../../Components/Header/Header";
import { BeatLoader } from "react-spinners";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Utils from "../../Utils/Utils";

function EditTherapist() {
  const params = useParams();
  const navigate = useNavigate();
  const {
    therapists,
    deleteTherapist,
    getTherapist,
    editTherapist,
    actionLoading,
  } = useContext(AppContext);
  const [editInstructorDetails, setEditInstructorDetails] = useState({
    firstName: "",
    lastName: "",
    bio: "",
    phonenumber: "",
    // profession: "",
    email: "",
    desigination: "",
    experience: "",
    price: "",
    file: null,
  });

  const [therapist, setTherapist] = useState(null);
  const [dialogues, setDialogues] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState(null);
  const [formErrors, setFormErrors] = useState("");

  useEffect(() => {
    findTherapistFromList();
  }, [therapists]);

  useEffect(() => {
    if (therapist != null) {
      setEditInstructorDetails(therapist);
    }
  }, [therapist]);

  const findTherapistFromList = () => {
    if (!params.id) return;
    const locTherpist = therapists.find((x) => x._id === params.id);
    setTherapist(locTherpist);
  };

  const onChangeEditingField = (e) => {
    const { name, value } = e.target;
    setEditInstructorDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlePhone = (value) => {
    const number = value?.replace(/\D/g, "");
    setEditInstructorDetails((prev) => ({ ...prev, phonenumber: number }));
  };

  var regex = /^[A-Za-z0-9 ]+$/;

  const editTherapistSingle = (e) => {
    e.preventDefault();

    if (!regex.test(editInstructorDetails.experience)) {
      toast.error("Not allowed to enter special charectors!");
      return;
    }
    if (
      editInstructorDetails.firstName.length === 0 ||
      editInstructorDetails.email.length === 0 ||
      editInstructorDetails.price.length === 0 ||
      editInstructorDetails.phonenumber.length === 0
    ) {
      setError(true);
      return;
    }
    if (editInstructorDetails.experience)
      if (Object.keys(formErrors).length !== 0) return;

    const bodyFormData = new FormData();
    bodyFormData.append("firstName", editInstructorDetails.firstName);
    bodyFormData.append("lastName", editInstructorDetails.lastName);
    bodyFormData.append("bio", editInstructorDetails.bio);
    bodyFormData.append("phonenumber", editInstructorDetails.phonenumber);
    // bodyFormData.append("profession", editInstructorDetails.profession);
    bodyFormData.append("email", editInstructorDetails.email);
    bodyFormData.append("desigination", editInstructorDetails.desigination);
    bodyFormData.append("experience", editInstructorDetails.experience);
    bodyFormData.append("price", editInstructorDetails.price);

    if (file != null) {
      bodyFormData.append("file", Utils.createBlobFile(file));
    }

    const instructorId = params.id;

    editTherapist(bodyFormData, instructorId, {
      onSuccess: async (res) => {
        await getTherapist();
        toast.success("Edited Successfully!", {
          style: {
            backgroundColor: "#fff",
            padding: "16px",
            color: "#252525",
          },
        });
        setTimeout(() => {
          navigate("/therapist");
        }, 1000);
      },
      onFailed: (err) => console.log(err),
    });
  };

  const handleDeleteTherapist = async (id) => {
    confirmAlert({
      title: "Are you sure?",
      buttons: [
        {
          className: "confirm-btn",
          label: "Yes",
          onClick: () => {
            deleteTherapist(id, {
              onSuccess: async () => {
                await getTherapist();
                toast.success("deleted Successfully!", {
                  style: {
                    backgroundColor: "#fff",
                    padding: "16px",
                    color: "#252525",
                  },
                });
                setTimeout(() => {
                  navigate("/therapist");
                }, 1000);
              },
              onFailed: (err) => {
                console.log(err);
              },
            });
          },
        },
        {
          className: "clear-button",
          label: "No",
        },
      ],
    });
  };

  const onCrop = (view) => {
    setFile(view);
  };

  const closeCropModal = () => {
    setDialogues(false);
  };

  const navigateBack = async () => {
    navigate(-1);
    await getTherapist();
  };

  return (
    <div>
      <Toaster position="top-center" />
      <Header />

      <Container fluid="md">
        <main>
          <section className="">
            <div className="edit_therapist">
              {!dialogues && (
                <button className="back_btn" onClick={() => navigateBack()}>
                  <Ai.AiOutlineLeft />
                </button>
              )}

              {!dialogues && <h2>Edit Therapist</h2>}

              <form
                className="form-control add_form"
                onSubmit={(e) => editTherapistSingle(e)}
              >
                {dialogues && (
                  <div className="">
                    <Dialog
                      visible={dialogues}
                      header={() => (
                        <div className="">
                          <p className="text-center">
                            Update New Profile Picture
                          </p>
                        </div>
                      )}
                      onHide={() => setDialogues(false)}
                    >
                      <div className="confirmation-content flex flex-column align-items-center">
                        <div className="">
                          <div>
                            <Avatar
                              width={400}
                              height={300}
                              onClose={closeCropModal}
                              onCrop={onCrop}
                            />
                            <div className="text-center mx-auto">
                              <Button
                                label="Save"
                                onClick={closeCropModal}
                                className="mt-4 mb-3"
                              >
                                Save
                              </Button>
                              <Button
                                label="Save"
                                onClick={closeCropModal}
                                className="mt-4 mb-3 m-3"
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog>
                  </div>
                )}
                {!dialogues && (
                  <>
                    <Row>
                      <Col>
                        <div className="profile">
                          <div className="flex flex-column justify-content-center align-items-center">
                            <img
                              // src={file != null ? file : Profile}
                              src={
                                file != null
                                  ? file
                                  : editInstructorDetails.photo != null
                                  ? process.env.REACT_APP_IMAGE_URL +
                                    editInstructorDetails.photo
                                  : Profile
                              }
                              alt=""
                              style={{ maxWidth: "100%" }}
                              onClick={() => {
                                setDialogues(true);
                              }}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="col-lg-4 col-md-4 col-sm-6 mt-2">
                        <div class="form-group">
                          <label for="name" className="text-nowrap">
                            First Name
                          </label>{" "}
                          <span style={{ color: "red" }}> *</span>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            value={editInstructorDetails.firstName}
                            onChange={onChangeEditingField}
                          ></input>
                          {error &&
                            editInstructorDetails.firstName.length == 0 && (
                              <span className="err-msg">
                                FirstName is required!
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 mt-2">
                        <div class="form-group last_name">
                          <label for="name" className="text-nowrap">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            value={editInstructorDetails.lastName}
                            onChange={onChangeEditingField}
                          ></input>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 mt-2">
                        <div className="top_margin">
                          <div class="form-group">
                            <label for="email">Email</label>{" "}
                            <span style={{ color: "red" }}> *</span>
                            <input
                              name="email"
                              type="email"
                              className="form-control"
                              value={editInstructorDetails.email}
                              onChange={onChangeEditingField}
                            />
                            {error &&
                              editInstructorDetails.email.length == 0 && (
                                <span className="err-msg">
                                  Email is required!
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 mt-3">
                        <div className="phone_form">
                          <div className="form-group">
                            <label for="phone">Phone</label>{" "}
                            <span style={{ color: "red" }}> *</span>
                            <input
                              type="text"
                              className="form-control"
                              name="phonenumber"
                              value={editInstructorDetails.phonenumber}
                              onChange={(e) => handlePhone(e.target.value)}
                            />
                            {error &&
                              editInstructorDetails.phonenumber.length == 0 && (
                                <span className="err-msg">
                                  Mobile Number is required!
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-4 col-md-4 col-sm-6 mt-3">
                        <div className="phone_form">
                          <label>Profession</label>
                          <input
                            type="text"
                            name="profession"
                            className="form-control mb-3"
                            value={editInstructorDetails.profession}
                            onChange={onChangeEditingField}
                          />
                          {error &&
                            editInstructorDetails.profession.length == 0 && (
                              <span className="err-msg">
                                Profession is required!
                              </span>
                            )}
                        </div>
                      </div> */}
                      <div className="col-lg-4 col-md-4 col-sm-6 mt-3">
                        <div className="job_form">
                          <label for="">Designation</label>
                          <input
                            type="text"
                            name="desigination"
                            className="form-control"
                            value={editInstructorDetails.desigination}
                            onChange={onChangeEditingField}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 mt-3">
                        <div className="experience_form">
                          <label for="">Experience</label>
                          <input
                            type="text"
                            name="experience"
                            className="form-control"
                            value={editInstructorDetails.experience}
                            onChange={onChangeEditingField}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="experience_form">
                          <label for="">Bio</label>
                          <input
                            type="text"
                            name="bio"
                            className="form-control"
                            value={editInstructorDetails.bio}
                            onChange={onChangeEditingField}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 ">
                        <div className="experience_form">
                          <label for="Weekly Price">Session Price</label>{" "}
                          <span style={{ color: "red" }}> *</span>
                          <input
                            name="price"
                            type="text"
                            className="form-control"
                            value={editInstructorDetails?.price}
                            onChange={onChangeEditingField}
                          />
                          {error && editInstructorDetails.price.length == 0 && (
                            <span className="err-msg">
                              Session Price is required!
                            </span>
                          )}
                        </div>
                      </div>
                    </Row>

                    {/* SocialMedia Icon */}
                    {/* <Row>
                <Col className="text-center">
                  <i className="socialmedia">
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillFacebook />
                    </a>
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillTwitterSquare />
                    </a>
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillInstagram />
                    </a>
                    <a href="" className="icon_socialmedia">
                      <Ai.AiFillLinkedin />
                    </a>
                  </i>
                </Col>
              </Row> */}
                    <Row className="buttons">
                      <Col className="text-center">
                        <Col className="mx-auto">
                          <button type="submit" className="edit_btn">
                            {actionLoading.action === "Edit" &&
                            actionLoading.loading === true ? (
                              <BeatLoader color="#fff" />
                            ) : (
                              "Update"
                            )}
                          </button>
                          {/* <button
                      type="button"
                      className="wid"
                      data-placement="top"
                      onClick={() => handleDeleteTherapist(params.id)}
                    >
                      {actionLoading.action === "Delete" &&
                      actionLoading.loading === true ? (
                        <BeatLoader size={5} color="#fff" />
                      ) : (
                        <Ai.AiFillDelete />
                      )}
                    </button> */}
                        </Col>
                      </Col>
                    </Row>
                  </>
                )}
              </form>
            </div>
          </section>
        </main>
      </Container>
    </div>
  );
}

export default EditTherapist;
