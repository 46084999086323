import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Header from "../../Components/Header/Header";
import { AppContext } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import * as Ai from "react-icons/ai";
function EditUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { registerdUsers, getRegisteredUsers, updateUser } =
    useContext(AppContext);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const getSingleUser = () => {
    if (!registerdUsers) return;
    const userdata = registerdUsers.find((x) => x._id === id);

    setUserData((prev) => ({
      ...prev,
      userId: userdata._id,
      name: userdata.name,
      phonenumber: userdata.phonenumber,
      email: userdata.email,
    }));
  };
  useEffect(() => {
    getSingleUser();
  }, [registerdUsers]);

  const handleChange = (e) => {
    const { name, value } = e;
    return setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneNumber = (value) => {
    const number = value.replace(/[^\d]/g, "");
    if (number.length >= 0) {
      setUserData((prev) => ({ ...prev, phonenumber: number }));
    }
  };

  const handleUpdateClient = async (e) => {
    e.preventDefault();

    if (
      userData?.name?.length === 0 ||
      userData?.email?.length === 0 ||
      userData?.password?.length === 0
    ) {
      setError(true);
    } else {
      setError(false);
      setUserLoading(true);
      await updateUser(userData, {
        onSuccess: async (res) => {
          toast.success("Successfully Updated User!");

          setError(false);

          await getRegisteredUsers();
          setTimeout(() => {
            navigate("/client");
            setUserLoading(false);
          }, 1000);
        },
        onFailed: (err) => {
          console.log(err.message);
          toast.error(err.message);
          setUserLoading(false);
        },
      });
    }
  };
  return (
    <>
      <Header />
      <Toaster position="top-center" />
      <main>
        <section className="main pl-4">
          <button
            className="back_btn"
            style={{ position: "absolute" }}
            onClick={() => navigate(-1)}
          >
            <Ai.AiOutlineLeft />
          </button>
          <div className="mx-auto">
            <div className="row">
              <div className="col-md-8 mx-auto edit-client">
                <div className="card p-3">
                  <div className="">
                    <div className="col-md-12">
                      <div className="col-md-10 mx-auto ">
                        <div className="mx-auto text-center "></div>
                        <form
                          className="client-form"
                          onSubmit={(e) => handleUpdateClient(e)}
                        >
                          <h5 className="text-center add-c mt-3">
                            Edit Client
                          </h5>
                          <div className="col">
                            <div className="form-group">
                              <label for="program name" className="p-1">
                                Full name
                              </label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={userData?.name ?? "N/A"}
                                onChange={(e) => handleChange(e.target)}
                              />
                              {userData?.name != null &&
                                userData?.name.length == 0 &&
                                error && (
                                  <span className="err_msg">
                                    Please Enter Your Name!
                                  </span>
                                )}
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label for="program name" className="p-1">
                                Email
                              </label>
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                value={userData?.email ?? "N/A"}
                                onChange={(e) => handleChange(e.target)}
                                disabled
                              />
                              {userData?.email != null &&
                                userData?.email.length == 0 &&
                                error && (
                                  <span className="err_msg">
                                    Please Enter Your Email!
                                  </span>
                                )}
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label for="program name" className="p-1">
                                Phone
                              </label>
                              <input
                                type="text"
                                name="phonenumber"
                                className="form-control"
                                value={userData?.phonenumber ?? "N/A"}
                                onChange={(e) =>
                                  handlePhoneNumber(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col text-center mt-3">
                            {userLoading ? (
                              <button
                                type="submit"
                                className="form_btn"
                                disabled
                              >
                                ...
                              </button>
                            ) : (
                              <button type="submit" className="form_btn">
                                Update
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EditUser;
