import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hook/useAuth";
import "./Login.css";
import useCookies from "../../hook/useCookies";
import jwt_decode from "jwt-decode";
import toast, { Toaster } from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import * as Ai from "react-icons/ai";

function Login() {
  const navigate = useNavigate();

  const [isUsername, setIsUsername] = useState("");
  const [isPassword, setIsPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  //Hook for Auth API
  const { loginUser, isLoading } = useAuth();

  //Hook for Cookie
  const { saveValueToCookies, checkValueIsExist } = useCookies({
    key: "UserPersistent",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    //Field Validation
    if (isUsername.length === 0 || isPassword.length === 0) {
      setError(true);
      return;
    }
    login();
  };
  //user Login here
  function login() {
    if (!isUsername || !isUsername) return;
    let payload = {
      email: isUsername,
      password: isPassword,
    };

    loginUser(payload, {
      onSuccess: (data) => {
        const { accessToken } = data;
        // console.log("Response", accessToken);
        if (!accessToken) return;
        const { role } = jwt_decode(accessToken);
        if (!role) return;

        saveValueToCookies({ accessToken, role });
        const checkIfSaved = checkValueIsExist();
        if (!checkIfSaved) return;
        setTimeout(() => {
          window.location.href = "/";
        }, 1200);
        toast.success(`Welcome to Nest ${role} 👍`, {
          style: {
            background: "#188351",
            border: "1px solid #95a28f",
            padding: "16px",
            color: "#fff",
          },
          duration: 1200,
        });
      },
      onFailed: (err) => {
        console.log("failed", err);
        toast.error(
          err?.errormessage?.disabled
            ? err?.message
            : "Login Failed 😟 Please Check the username and password",
          {
            style: {
              backgroundColor: "#d53343",
              padding: "16px",
              color: "#fff",
            },
          }
        );
        console.log(err);
      },
    });
  }
  const handlePassword = () => {
    navigate("/forgot-password");
  };
  return (
    <>
      <Toaster position="top-center" />
      <div className="login_container">
        <div className="loginForm">
          <div className="logo">
            <img src={require("../../assets/logo_main.png")} />
          </div>
          <br />
          <div className="container">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="form-outline mb-4">
                <input
                  type="text"
                  id="form2Example1"
                  className={
                    error && isUsername?.length === 0
                      ? "form_field validated"
                      : "form_field "
                  }
                  placeholder="Enter Email"
                  value={isUsername}
                  onChange={(e) => setIsUsername(e.target.value)}
                  autoComplete="off"
                />
                {error && isUsername?.length === 0 ? (
                  <>
                    <span className="errors_">Username Cannot be blank</span>
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="form-outline mb-4 pwd">
                <input
                  type={passwordType}
                  onChange={(e) => setIsPassword(e.target.value)}
                  value={isPassword}
                  className={
                    error && isPassword?.length === 0
                      ? "form_field validated"
                      : "form_field "
                  }
                  placeholder="Enter Password"
                  name="password"
                  autoComplete="off"
                />
                <span className="p-viewer">
                  <i
                    className="pass_eye"
                    onClick={togglePassword}
                    style={{ cursor: "pointer" }}
                  >
                    {passwordType === "password" ? (
                      <Ai.AiFillEyeInvisible />
                    ) : (
                      <Ai.AiFillEye />
                    )}
                    {error && isPassword?.length === 0 ? (
                      <>
                        <span className="errors_">
                          Password cannot be blank
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </i>
                </span>
              </div>
              <p className="f_pas" onClick={() => handlePassword()}>
                Forgot Password?
              </p>
              <button type="submit" className="login_btn">
                {isLoading ? <BeatLoader color="#fff" /> : "Login"}
              </button>
            </form>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
