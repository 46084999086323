import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Utils from "../../Utils/Utils";

function AllStreams({ onStreamStart }) {
  const { therapistSchedules, programs, registerdUsers } =
    useContext(AppContext);

  return (
    <>
      <Container className="py-4">
        {therapistSchedules.schedules &&
          Array.isArray(therapistSchedules.schedules) &&
          therapistSchedules.schedules.map((stream) => {
            const cancelledDetails = stream?.cancelInformation;
            const therapist = therapistSchedules.therapistDetails;
            const sessiondate = therapistSchedules.bookings;
            const schedule = stream.scheduleDetails;
            const userId = stream.userIds;

            const therapy = programs.find((x) => x._id == schedule.therapyId);

            const findUser = registerdUsers.filter((y) =>
              userId.includes(y._id)
            );

            console.log("stream status==>", stream.bookingStatus);

            return (
              <div class="card schedule_card schedule_details_card mt-3">
                <Row>
                  <Col xl={3}>
                    {/* <p style={{ fontWeight: "500" }}>
                      Therapist Name : <span>{therapist?.name ?? "N/A"}</span>
                    </p> */}
                    <p style={{ fontWeight: "500" }}>
                      Schedule Type:{" "}
                      {!stream.dateSchedule ? (
                        <span>Weekly {`(${stream?.week ?? "N/A"})`}</span>
                      ) : (
                        <span>Date-wise ({Utils.formatDate(stream.date)})</span>
                      )}
                    </p>
                    <div className="det">
                      <div className="icon_div">
                        <p style={{ fontWeight: "500" }}>
                          Client Name :{" "}
                          <span>
                            {findUser.map((name) => name.name).join(", ")}
                          </span>
                        </p>
                      </div>
                      <div className="icon_div">
                        <p style={{ fontWeight: "500" }}>
                          Time :{" "}
                          <span>
                            {schedule.startTime} to {schedule.endTime}
                          </span>
                        </p>
                      </div>
                      {/* <div className="icon_div">
                        <p style={{ fontWeight: "500" }}>
                          Info :{" "}
                          <span style={{ fontWeight: "400" }}>
                            Here shows the instructions
                          </span>
                        </p>
                      </div> */}
                    </div>
                  </Col>
                  <Col xl={6}>
                    <p style={{ fontWeight: "500" }}>
                      Therapy Name :{" "}
                      <span>{therapy?.therapyName ?? "N/A"}</span>{" "}
                    </p>

                    <p style={{ fontWeight: "500" }}>
                      Date :{" "}
                      <span>{Utils.formatDate(stream?.date ?? "N/A")}</span>{" "}
                    </p>
                    <p className="">{therapy?.therapyDescription}</p>
                    <span></span>
                  </Col>
                  <Col xl={3}>
                    {stream.scheduleStatus == "pending" &&
                    stream.bookingStatus != "CANCELED" ? (
                      <div class="start_stream_btn mx-auto not_acc_">
                        Not Accepted
                      </div>
                    ) : (
                      <div className="row mt-3">
                        {stream.bookingStatus == "CANCELED" ? (
                          <p className="canceled-session">
                            This session is Cancelled by{" "}
                            {cancelledDetails?.cancelledBy} on{" "}
                            {Utils.formatDate(
                              cancelledDetails?.cancelledDate
                            ) ?? "Date Not Availble"}
                            <br />
                          </p>
                        ) : (
                          <button
                            class="start_stream_btn mx-auto"
                            onClick={() =>
                              onStreamStart({ ...stream, therapist })
                            }
                            disabled={
                              stream.streamStatus == "STARTED" ? true : false
                            }
                          >
                            {stream.streamStatus == "STOPPED"
                              ? "Restart"
                              : "Start"}
                          </button>
                        )}
                      </div>
                    )}

                    <div class="live_indicator"></div>
                  </Col>
                </Row>
              </div>
            );
          })}
      </Container>
    </>
  );
}

export default AllStreams;
