import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Header from "../../Components/Header/Header";
import { AppContext } from "../../context/AppContext";
import ScheduleModal from "./ScheduleModal";
import useCookies from "../../hook/useCookies";
import toast, { Toaster } from "react-hot-toast";
import ScheduleManagementTherapist from "./ScheduleManagementTherapist";
import * as Ai from "react-icons/ai";
import "./ScheduleManagement.css";
import { Row, Col, Container } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import * as Bs from "react-icons/bs";
function ScheduleManagement() {
  const navigate = useNavigate();
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [openAddModal, setOpenAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTherapistId, setSelectedTherapistId] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const {
    scheduledTheraphies,
    scheduledSlot,
    getListOfScheduledSlots,
    programs,
    deleteSchedule,
    pendingDateScheduleList,
    getAllScheduleListInAdmin,
    allSchedules,
  } = useContext(AppContext);
  const [isRole, setIsRole] = useState(null);

  const { getValueFromCookies } = useCookies({ key: "UserPersistent" });

  useEffect(() => {
    const { role } = getValueFromCookies();
    setIsRole(role);
  }, []);
  const handleTherapistSelection = (therapistId) => {
    setSelectedTherapistId(therapistId);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const ItemsSchedule = scheduledSlot.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <Header />
      <ScheduleModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
      />
      <Toaster position="top-center" />
      <main>
        <section class="main pl-3">
          {isRole === "superadmin" && (
            <>
              <button
                className="back_btn"
                style={{ position: "absolute" }}
                onClick={() => navigate("/settings")}
              >
                <Ai.AiOutlineLeft />
              </button>
              <button
                className="add_btn"
                onClick={() => navigate("/add-schedule-admin")}
              >
                + Add
              </button>
              <h1 className="schedule-head">Schedule Management - Admin</h1>
              <div class="card div-manage">
                <table
                  class="table table-bordered"
                  style={{ textAlign: "center" }}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Therapist</th>
                      <th>Desigination</th>
                      <th>Number of schedules</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSchedules != null &&
                      allSchedules.map((schedule, index) => {
                        // console.log("sch", allSchedules);
                        const scheduleId =
                          schedule.weeklySchedule &&
                          schedule.weeklySchedule.length != 0
                            ? schedule.weeklySchedule[0]._id
                            : schedule.DateSchedule &&
                              schedule.DateSchedule.lenght != 0
                            ? schedule.DateSchedule[0]._id
                            : "";

                        //to get the count of schedules
                        let dateDataLength = 0;
                        let weekDataLength = 0;
                        schedule?.DateSchedule &&
                          schedule?.DateSchedule.length !== 0 &&
                          schedule?.DateSchedule.forEach((data) => {
                            dateDataLength += data.schedules.length;
                          });

                        schedule?.weeklySchedule &&
                          schedule?.weeklySchedule.length !== 0 &&
                          schedule?.weeklySchedule.forEach((data) => {
                            weekDays.forEach((day) => {
                              if (data[day] !== null) {
                                weekDataLength += data[day].length;
                              }
                            });
                          });

                        // console.log("weekDataLength", dateDataLength, weekDataLength);

                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{schedule?.therapistDetails?.name ?? "N/A"}</td>
                            <td>
                              {schedule?.therapistDetails?.desigination ??
                                "N/A"}
                            </td>
                            <td>{weekDataLength + dateDataLength}</td>
                            <td>
                              <button
                                className="view-sch"
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "selectedTherapistId",
                                    schedule?.therapistDetails._id ?? ""
                                  );
                                  sessionStorage.setItem(
                                    "therapistDetails",
                                    JSON.stringify(
                                      schedule?.therapistDetails
                                    ) ?? ""
                                  );
                                  window.location.href = `/schedule-approve/${scheduleId}/${schedule.therapistId}`;
                                }}
                              >
                                <Ai.AiFillEye />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {isRole === "instructor" && (
            <>
              <ScheduleManagementTherapist />
            </>
          )}

          <div className="pagnte">
            {/* <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(scheduledSlot.length / itemsPerPage)}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              disabledClassName={"disabled"}
              activeClassName={"paging__link--active"}
            /> */}
          </div>
        </section>
      </main>
    </>
  );
}

export default ScheduleManagement;
