import React, {
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
  Fragment,
} from "react";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import * as Si from "react-icons/si";
import * as Ri from "react-icons/ri";
import * as Md from "react-icons/md";
import * as Fa from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { CgSoftwareDownload } from "react-icons/cg";
import { IoCloseCircleSharp } from "react-icons/io5";

import "./LiveSession.css";
import { Row, Col, Container, Badge, ProgressBar } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MediaPlayer from "../../Pages/streamManager/Mediaplayer";
import useChat from "../../hook/useChat";
import { height } from "@mui/system";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
// import "animate.css";
import nestLogo from "../../assets/logo_main.png";
import placeholder from "../../assets/avatar2.png";

function ChatSession(props) {
  const { id } = useParams();
  const newMsg = useRef(null);
  const uploadRef = useRef();
  const pinnedref = useRef(false);
  const {
    mainPlayerSrc,
    localTracks,
    loggedInstructorDetails,
    remoteUsers,
    players,
    setPlayers,
    allStreams,
    programs,
    registerdUsers,
    fileSharingToUser,
    fileLoading,
    progress,
    setProgress,
  } = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState("All");
  const [msgNotif, setMsgNotif] = useState(false);
  const [shareFile, setShareFile] = useState(null);
  const [therapistDetails, setTherapistDetails] = useState(null);

  const {
    message,
    chatMessages,
    clearChat,
    sendMessageToRoom,
    setMessage,
    whenFileShared,
  } = useChat({
    chatRoomId: props.chatRoomId,
    userDetails: loggedInstructorDetails,
    userType: "Therapist",
    onMessageRecieved: (message) => {
      if (message != null && message.userType === "Client") {
        setMsgNotif(true);
        setTimeout(() => {
          setMsgNotif(true);
          toast("New Message Received!", {
            icon: "🚩",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }, 1800);
        setMsgNotif(false);
        // console.log("message from", msgNotif);
      }
    },
  });

  const onChangeMessageField = (e) => {
    setMessage(e.target.value);
  };

  const onPressKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      sendMessageToRoom();
    }
  };

  useEffect(() => {
    const container = newMsg.current;
    if (!pinnedref.current) {
      container.scrollTop = container.scrollHeight;
    }

    const therapistDetails = JSON.parse(
      sessionStorage.getItem("TherapistDetails")
    );
    setTherapistDetails(therapistDetails);
  }, []);

  useLayoutEffect(() => {
    const container = newMsg.current;
    const pinned =
      container.scrollHeight - container.scrollTop === container.clientHeight;
    pinnedref.current = pinned;
  });

  const getSingleUserDetails = (userId) => {
    if (props.users === null) return;
    const userData = props.users;
    const user = userData.length != 0 && userData.find((x) => x?._id == userId);

    console.log("user", user);

    return user?.name ?? "Client";
  };

  const handleFileUpload = async () => {
    if (!shareFile) {
      sendMessageToRoom();
      return;
    }

    const formData = new FormData();

    formData.append("file", shareFile);
    formData.append("roomId", id);
    formData.append("bookingId", id);
    formData.append("userType", "Therapist");
    formData.append(
      "username",
      therapistDetails != null ? therapistDetails.name : "N/A"
    );
    formData.append(
      "userId",
      therapistDetails != null ? therapistDetails._id : "N/A"
    );

    await fileSharingToUser(formData, {
      onSuccess: (res) => {
        toast.success(res.message);
        setShareFile(null);
        console.log("fileUpload response", res);
      },
      onFailed: (err) => {
        toast.error(err);
      },
    });
  };

  return (
    <>
      <Toaster position="top-center" />

      <div className="chat_main">
        <div className="chat_head">
          <div className="tab-div mx-auto">
            <Tabs
              defaultActiveKey="All"
              className="mx-auto m-3"
              justify
              style={{ width: "100%" }}
              onSelect={(e) => {
                setSelectedTab(e);
                setMsgNotif(false);
              }}
            >
              <Tab
                eventKey="All"
                title={
                  <span style={{ fontSize: "13px" }}>
                    <i style={{ fontSize: "17px", marginRight: "2px" }}>
                      <Ri.RiUserSmileFill />
                    </i>{" "}
                    Participants
                  </span>
                }
                id="scrollable"
              >
                <div className="scroll-area">
                  {remoteUsers.length != 0 &&
                    localTracks != null &&
                    Object.keys(localTracks).length != 0 && (
                      <div className="card part_card mx-auto">
                        <MediaPlayer
                          id={"sidePlayer"}
                          videoTrack={localTracks.localVideoTrack}
                          audioTrack={localTracks.localAudioTrack}
                          style={{ aspectRatio: "16/9" }}
                          ref={React.createRef()}
                          userName={"Me"}
                        />
                      </div>
                    )}
                  {remoteUsers.map((user, index) => {
                    const player = players.find((p) => p.uid === user.uid);

                    if (!player) {
                      const newPlayer = {
                        uid: user.uid,
                        ref: React.createRef(),
                      };
                      setPlayers([...players, newPlayer]);
                    }

                    const pinnedUserId =
                      window.sessionStorage.getItem("pinnedUserId");
                    if (pinnedUserId == user.uid) {
                      return null;
                    }

                    // const matches = props.checkMainPlayerUidMatches(user.uid);
                    // if (matches == true) {
                    //   return null;
                    // }

                    return (
                      <>
                        <div className="card part_card mx-auto">
                          <div className="cont">
                            <div key={user.uid} className="mini-player">
                              <button
                                className="pin_btn"
                                onClick={() => {
                                  const currentPlayer = players.find(
                                    (p) => p.uid === user.uid
                                  );
                                  return (
                                    props.onClickPin &&
                                    props.onClickPin({
                                      index,
                                      videoTrack: user.videoTrack,
                                      audioTrack: user.audioTrack,
                                      uid: user.uid,
                                      player:
                                        currentPlayer &&
                                        currentPlayer.ref.current,
                                    })
                                  );
                                }}
                              >
                                <Bs.BsFillPinAngleFill />
                              </button>
                              {player && (
                                <MediaPlayer
                                  id={`player-${user.uid}`}
                                  videoTrack={user.videoTrack}
                                  audioTrack={user.audioTrack}
                                  style={{ aspectRatio: "16/9" }}
                                  onPlayerReady={(player) => {
                                    return (
                                      props.onPlayerReady &&
                                      props.onPlayerReady(player)
                                    );
                                  }}
                                  ref={player.ref}
                                  remoteUserId={user.uid}
                                  mainPlayer={false}
                                  temp="temp"
                                />
                              )}
                              <button className="btn-cont">
                                {!user.hasAudio ? (
                                  <Bs.BsMicFill />
                                ) : (
                                  <Bs.BsMicMuteFill />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </Tab>

              <Tab
                eventKey="Messages"
                title={
                  <Fragment>
                    <span style={{ fontSize: "13px" }}>
                      <i style={{ fontSize: "17px", marginRight: "2px" }}>
                        <Si.SiGooglemessages />
                      </i>{" "}
                      Messages
                    </span>
                    {msgNotif ? (
                      <Badge bg="danger" variant="danger" className="ms-2">
                        1
                      </Badge>
                    ) : (
                      ""
                    )}
                  </Fragment>
                }
                className="tab"
              >
                <button
                  class="clear_chat"
                  onClick={() => clearChat()}
                  title="Clear chat"
                >
                  <Md.MdClearAll />
                </button>
                <div class="chat_info">
                  <h5>Please let us know if you face any issues.</h5>
                  <h6>
                    <Ai.AiOutlineMail /> mail@nesthealthcare.com
                  </h6>
                </div>
                <div className="chat_area_left" id="chat" ref={newMsg}>
                  {/* chat Content */}
                  {fileLoading && (
                    <div class="animate__animated animate__fadeInUp uploading">
                      uploading..
                    </div>
                  )}
                  {/* {shareFile != null && (
                    <div class="file_selected">
                      {shareFile.name}{" "}
                      <i onClick={() => setShareFile(null)}>
                        <IoIosSend />
                      </i>
                    </div>
                  )} */}
                  {loggedInstructorDetails &&
                    Array.isArray(chatMessages) &&
                    chatMessages.length != 0 &&
                    chatMessages.map((message) => {
                      const currentUser =
                        message.userId == loggedInstructorDetails._id;

                      let filename;

                      if (message != null && message.type === "file") {
                        const fileUrl = message.fileUrl;
                        const parts = fileUrl.split("/");
                        filename = parts[parts.length - 1];
                      }

                      return !currentUser ? (
                        <Fragment>
                          <div class="chat d-flex mt-2">
                            <div class="avt">
                              <img
                                src={require("../../assets/avatar2.png")}
                                alt=""
                              />
                            </div>
                            {message != null && message.type === "file" ? (
                              <div class="txt">
                                <div class="shared_file">
                                  <a
                                    href={
                                      process.env
                                        .REACT_APP_IMAGE_URL_FILE_SHARE +
                                      message?.fileUrl
                                    }
                                    class="file_download"
                                    target="_blank"
                                  >
                                    <i>
                                      <CgSoftwareDownload />
                                      <a />
                                    </i>
                                    <span>{filename ?? "download"}</span>
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div class="txt">
                                {/* <p
                                  className="m-0"
                                  style={{
                                    fontSize: "11px",
                                    padding: "0 10px",
                                    fontWeight: "bold",
                                    textAlign:"left"
                                  }}
                                >
                                  {message && message?.userId
                                  ? getSingleUserDetails(message?.userId)
                                  : "Client"}
                                </p> */}
                                <div className="txt_cont">
                                  {message.message}
                                </div>
                              </div>
                            )}
                          </div>
                        </Fragment>
                      ) : (
                        <div class="chat-right d-flex mt-2">
                          {message != null && message.type === "file" ? (
                            <div class="txt">
                              <div class="shared_file mt-2">
                                <a
                                  href={
                                    process.env.REACT_APP_IMAGE_URL_FILE_SHARE +
                                    message?.fileUrl
                                  }
                                  class="file_download"
                                  target="_blank"
                                >
                                  <i>
                                    <CgSoftwareDownload />
                                    <a />
                                  </i>
                                  <span>{filename ?? "download"}</span>
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div class="txt">
                              {/* <p
                                className="m-0"
                                style={{
                                  fontSize: "11px",
                                  padding: "0 10px",
                                  fontWeight: "bold",
                                  textAlign:"right"
                                }}
                              >
                                Therapist
                              </p> */}
                              <div className="txt_cont">{message.message}</div>
                            </div>
                          )}
                          <div class="avt" id="right">
                            <img
                              src={
                                loggedInstructorDetails &&
                                loggedInstructorDetails.photo
                                  ? process.env.REACT_APP_IMAGE_URL +
                                    loggedInstructorDetails?.photo
                                  : nestLogo
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                  {/* chat content end */}
                </div>
                {/* Chat Area end */}

                {/* <ProgressBar variant="success" now={progress} /> */}

                <div className="sent-box">
                  <input
                    className="p-3 msg-content"
                    placeholder={
                      shareFile != null ? shareFile.name : "write a message...."
                    }
                    onChange={onChangeMessageField}
                    onKeyDown={onPressKeyDown}
                    value={message}
                  />
                  <button
                    class="file_button"
                    onClick={() => {
                      uploadRef.current.click();
                    }}
                  >
                    <Md.MdAttachFile />
                  </button>
                  <input
                    type="file"
                    name=""
                    id="fileUpload"
                    ref={uploadRef}
                    style={{ display: "none" }}
                    onChange={(e) => setShareFile(e.target.files[0])}
                  />
                  <button class="send_" onClick={handleFileUpload}>
                    <IoIosSend />
                  </button>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatSession;
