export default class Utils {
  static checkObjectAllValuesDefined(obj) {
    for (let prop in obj) {
      if (typeof obj[prop] === "undefined") {
        return false;
      }
    }
    return true;
  }

  static createFormDataFromFormValues(values) {
    const form = new FormData();

    Object.keys(values).forEach(function (key, index) {
      form.append(key, values[key]);
    });
    return form;
  }

  static reverseDateString(dateString) {
    var parts = dateString.split("-");
    if (parts.length === 3) {
      var year = parts[0];
      var month = parts[1];
      var day = parts[2];
      return day + "-" + month + "-" + year;
    } else {
      throw new Error(
        "Invalid date string format. Expected format: yyyy-mm-dd"
      );
    }
  }

  static formatDate(value) {
    let date;

    // Handle ISO 8601 format
    if (typeof value === "string" && value.includes("T")) {
      date = new Date(value);
    }
    // Handle MM-DD-YYYY format 
    else if (typeof value === "string" && /^\d{2}-\d{2}-\d{4}$/.test(value)) {
      const [month, day, year] = value.split("-");
      date = new Date(`${year}-${month}-${day}`);
    }
    // Handle DD-MM-YYYY format
    else if (typeof value === "string" && /^\d{2}-\d{2}-\d{4}$/.test(value)) {
      const [day, month, year] = value.split("-");
      date = new Date(`${year}-${month}-${day}`);
    }
    // If the date is still invalid, return null or handle the error
    if (isNaN(date)) {
      return null;
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Create a normal date string in DD-MM-YYYY format
    const normalDate = `${day}-${month}-${year}`;

    return normalDate;
  }

  static formatDateByYear(value) {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Create a normal date string
    const normalDate = `${year}-${month}-${day}`;

    return normalDate;
  }

  static createBlobUrl(base64String) {
    const binaryData = atob(base64String.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: "image/png" });
    return URL.createObjectURL(blob);
  }

  static createBlobFile(base64String) {
    const binaryData = atob(base64String.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: "image/png" });
    return new File([blob], "image.png");
  }
  static checkObjectAllValuesDefined(obj) {
    for (let prop in obj) {
      if (typeof obj[prop] === "undefined") {
        return false;
      }
    }
    return true;
  }

  static convertStringToNumeric(value) {
    let timeArray = value.split(":");
    let hours = parseInt(timeArray[0]);
    let minutes = parseInt(timeArray[1]);

    if (value.includes("PM") && hours !== 12) {
      hours += 12;
    } else if (value.includes("AM") && hours === 12) {
      hours = 0;
    }

    let numericTime = hours * 100 + minutes;

    return numericTime;
  }

  static isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }
}
