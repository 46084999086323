export const APIEndpoints = {
  //Auth EndPoint
  login: "admin/login",
  confirmTherapist: "admin/addinstructorpassword",

  // Therapist's EndPoint
  addTherapist: "admin/addinstructor",
  getTherapist: "admin/allinstructors",
  editTherapist: "admin/editInstructor",
  deleteTherapist: "admin/deleteinstructor",
  therapistDetails: "admin/instructordetail",
  ScheduledSessionList: "admin/getTherapistScheduledList",
  enableTherapist: "admin/enableinstructor",
  disableTherapist: "admin/disableinstructor",
  editInstructor: "admin/editInstructor",
  getClientTherapyList: "admin/gettherapistscheduledlistalpha",
  updateScheduleByTherapist: "admin/updateBookingSheduleStatus",
  deleteInstructor: "admin/deleteTherapist",

  // Therapy EndPoint
  addTherapy: "admin/addtherapy",
  getTherapy: "admin/alltherapies",
  editTherapy: "admin/edittherapy",
  deleteTherapy: "admin/deleteTherapy",
  scheduleTherphy: "admin/scheduletherapy",
  listScheduleTherapies: "admin/listscheduletherapies",
  getScheduledTherapyByTherapist: "admin/gettherapistscheduledlist",
  disableTherapy: "admin/disabletherapy",
  enableTherapy: "admin/enabletherapy",
  deleteSchedule: "admin/delete-dateSchedule",
  makePopularize: "admin/popularize-therapy",
  pendingDateSchedule: "admin/listPendingDateSchedulesForApproval",
  approveDateSchedule: "admin/approvedateschedule",

  //Category EndPoint
  addCategory: "admin/addcategory",
  getCategory: "admin/allcategories",
  editCategory: "admin/editcategory",
  deleteCategory: "admin/deletecategory",

  //Schedule Stream
  changeScheduleStreamStatus: "admin/changebookedscheduledstreamstatus",
  scheduleTherapyByTherapist: "admin/weeklyschedule",
  cancelSession: "admin/cancel-schedule",

  //Users
  listAllRegisteredUsers: "admin/allusers",
  deleteUser: "user/delete-user",
  registerUser: "admin/addUserByAdmin",
  editUser: "admin/editUserByAdmin",

  //Purchase
  listAllPruchases: "admin/getpurchasehistory",
  rescheduleSession: "admin/re-schedule",

  //weekly scedule
  weeklySchedule: "admin/weeklyschedule",
  getWeeklyScheduleList: "admin/getweeklyschedulelist",
  getListOfScheduledSlots: "admin/listPendingForApprovalSchedules",
  approveShceduledSlot: "admin/approveschedule",
  createBroadcastToken: "admin/createbroadcasttoken",

  //Date Schedule
  getDateSchedules: "admin/getdateschedulelist",
  dateSchedule: "admin/dateschedule",
  deletescheduleOfWeek: "admin/delete-weeklySchedule",
  consolidatedSchedules: "admin/all-schedules-list",

  //message
  fileSharing: "chat/share-file",

  //guestParticipation
  participateGuest: "admin/guest-request",
  VerifyGuest: "admin/guest-verification",
  getGuestList: "admin/fetch-guest-list",

  //forgotpasswordInstructor
  forgotPassword: "admin/forgot-password",
  otpVerification: "admin/OTP-verification",
  resetPassword: "admin/reset-password",
  resentOtp: "admin/resend-otp",

  //cancelRefundStatus
  updateCancellationAndRefundStatus: "admin/update-refund-status",
  sendNotesToClient: "admin/generate-note",
  rescheduleRequest: "admin/all-reschedule-requests",
  declineRequest: "admin/decline-reschedule-requests",
  acceptRequestFromClient: "admin/accept-reschedule-request",
  createNewSession: "admin/create-new-schedule",
  paymentStatusUpdate: "admin/update-payment-status",
};
