import React, { useEffect, useState, useContext } from "react";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import Utils from "../../../Utils/Utils";
import dayjs from "dayjs";
import { confirmAlert } from "react-confirm-alert";
import { DatePicker, Modal, Space, TimePicker } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { AppContext } from "../../../context/AppContext";
import { Schedule } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import ScheduleData from "../ScheduleData";

function DateScheduleInAdmin() {
  const {
    pendingDateScheduleList,
    programs,
    approveSingleDateSchedule,
    deleteSchedule,
    getPendingDateSchedule,
    therapists,
    getListOfScheduledSlots,
  } = useContext(AppContext);

  const { id, therapistId } = useParams();

  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [price, setPrice] = useState("");
  const [selectedTherapistId, setSelectedTherapistId] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [error, setError] = useState(false);
  const [approveId, setApproveId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [therapistSchedule, setTherapistSchedule] = useState(null);

  const [editScheduleData, setEditScheduleData] = useState({
    startTime: "N/A",
    endTime: "N/A",
    therapyId: "N/A",
    description: "N/A",
    therapistId: "N/A",
    date: "N/A",
  });

  useEffect(() => {
    getDateScheduleOfTherapist();
  }, [therapistId]);

  const findTherapyDataById = (id) => {
    return programs.find((x) => x._id == id);
  };

  const approveTherapy = async () => {
    if (price.length == 0) {
      setError(true);
      return;
    }
    const payload = {
      price,
      approved: true,
    };

    await approveSingleDateSchedule(approveId, payload, {
      onSuccess: async (res) => {
        toast.success("Schedule is Approved SuccessFully");
        await getPendingDateSchedule();
        setTimeout(() => {
          setPrice("");
          setShowScheduleModal(false);
        }, 2000);
      },
      onFailed: (error) => {
        toast.error("Approve Failed");
        console.log(error);
      },
    });
  };
  const handleTherapistSelection = (therapistId) => {
    setSelectedTherapistId(therapistId);
  };

  const DeleteSchedule = (id) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure you want to Delete this Schedule!",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleSchedule(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const showModal = (schedule) => {
    setIsModalOpen(true);
    setEditScheduleData({
      // date: therapistSchedule[0].date,
      therapyId: schedule.therapyId,
      startTime: schedule.startTime,
      endTime: schedule.endTime,
      description: schedule.description,
      therapistId: schedule.therapistId,
    });
    console.log("data", schedule);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSchedule = async (id) => {
    console.log(id);
    await deleteSchedule(id, {
      onSuccess: async (res) => {
        console.log(id);
        toast.success(res.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  const getDateScheduleOfTherapist = () => {
    if (!therapistId) return;

    const data = pendingDateScheduleList.filter(
      (schedule) => schedule.therapistId == therapistId
    );

    setTherapistSchedule(data);
  };
  // const onChangeTimePicker = (time, timeString, type) => {
  //   switch (type) {
  //     case "startTime":
  //       setEditedValues((prev) => ({ ...prev, startTime: timeString }));
  //       return;
  //     case "endTime":
  //       setEditedValues((prev) => ({ ...prev, endTime: timeString }));
  //       return;
  //     default:
  //       return;
  //   }
  // };
  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setEditScheduleData((prev) => ({ ...prev, [name]: value }));
  };

  const onDateSelect = (date, dateString) => {
    setEditScheduleData((prev) => ({
      ...prev,
      date: date,
    }));
  };

  console.log("datge", therapistSchedule);
  return (
    <>
      <Modal
        open={showScheduleModal}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setShowScheduleModal(false);
          setPrice("");
          setError(false);
        }}
        centered
      >
        <h3>Add Price to Schedule</h3>
        <p>{/* Therapy Name: <span>{therapyName}</span> */}</p>
        <p>
          Price:{" "}
          <input
            type="text"
            className="price form-control"
            value={price}
            placeholder={"Enter price"}
            onChange={(e) => setPrice(e.target.value)}
          />
          {error ? (
            <span style={{ color: "red" }}>Price connot be blank</span>
          ) : (
            ""
          )}
        </p>
        <button class="approve_btn" onClick={approveTherapy}>
          Approve
        </button>
        {/* <p>Therapist: }</p> */}
      </Modal>

      <div className="">
        <div className="row">
          {therapistSchedule &&
            therapistSchedule != null &&
            therapistSchedule.map((list, key) => {
              console.log("list", list);
              const scheduleData = list.schedules;
              const therapist = list.therapistDetails;
              const therapistId = list.therapistDetails._id;
              return (
                <>
                  <div
                    className="col-md-4 col-sm-6"
                    key={key}
                    onClick={() => setSelectedCardIndex(key)}
                  >
                    {scheduleData &&
                      scheduleData.length > 0 &&
                      scheduleData.map((schedule) => {
                        return (
                          <div class="m-0">
                            <div class="card date-card" id="date-pending-list">
                              <h1>
                                {schedule.therapyId != null
                                  ? findTherapyDataById(schedule.therapyId)
                                      ?.therapyName
                                  : "N/A"}
                              </h1>
                              <div class="d-flex">
                                <p>
                                  <i>
                                    <Ai.AiOutlineCalendar />
                                  </i>{" "}
                                  <span>{Utils.formatDate(list.date)}</span>
                                </p>
                                <p className="mx-3">
                                  <i>
                                    <Ai.AiOutlineClockCircle />
                                  </i>{" "}
                                  <span>{`${schedule?.startTime}-${schedule?.endTime}`}</span>
                                </p>
                              </div>
                              <p className="date-cont">
                                <i>
                                  <Ai.AiOutlineUser />
                                </i>{" "}
                                {therapist?.name ?? "N/A"}
                              </p>
                              <p className="date-cont pb-2">
                                <i>
                                  <Ai.AiOutlineMail />
                                </i>{" "}
                                {therapist?.email ?? "N/A"}
                              </p>

                              {/* <p className="date-cont" id="date-desc">
                                {schedule?.description ?? "N/A"}
                              </p> */}
                              {/* {!schedule.price && (
                              <button
                                class="approve"
                                id="date-approve"
                                title="Approve"
                                onClick={() => {
                                  setShowScheduleModal(true);
                                  setApproveId(schedule._id);
                                }}
                              >
                                <Bs.BsCheckAll />
                              </button>
                            )} */}

                              <div className="col text-center mt-3">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <button
                                      className="delete-sch"
                                      onClick={() =>
                                        DeleteSchedule(schedule._id)
                                      }
                                    >
                                      <Bs.BsTrash />
                                    </button>
                                  </div>
                                  {/* <div>
                                    <button
                                      className="week-del"
                                      onClick={() => showModal(schedule)}
                                    >
                                      <Bs.BsPencil />
                                    </button>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <>
                    {/* <Modal open={isModalOpen} closable={false}>
                      <div className="container">
                        <h4 className="text-center mb-3">Edit Date Schedule</h4>
                        <div className="row">
                          <div class="col">
                            <div class="form-group ">
                              <label for="">Date</label>
                              <Space
                                direction="vertical"
                                style={{
                                  width: "100%",
                                  borderColor: "transparent",
                                }}
                              >
                                <DatePicker
                                  style={{
                                    width: "100%",
                                    borderColor: "transparent",
                                    outline: "none",
                                    boxShadow: "0 0 0 transparent",
                                  }}
                                  format={"DD-MM-YYYY"}
                                  className="form-control"
                                  value={dayjs(
                                    therapistSchedule[selectedCardIndex]?.date
                                  )}
                                  onChange={onDateSelect}
                                />
                              </Space>
                            </div>
                          </div>
                          <div className="col">
                            <label for="">Therapist</label>
                            <select
                              name="therapyId"
                              id=""
                              class="form-select"
                              value={editScheduleData.therapistId}
                              onChange={(e) => onChnageTherapist(e)}
                            >
                              <option value="0" selected>
                                Select Therapist
                              </option>
                              {therapists.length > 0 &&
                                therapists.map((therapist, index) => {
                                  // console.log(therapy);
                                  return (
                                    <>
                                      <option value="0">
                                        Select Therapist
                                      </option>
                                      {therapists.length > 0 &&
                                        therapists.map((therapist, index) => (
                                          <option
                                            key={index}
                                            value={therapist._id}
                                          >
                                            {therapist.firstName +
                                              " " +
                                              therapist.lastName}
                                          </option>
                                        ))}
                                    </>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div class="form-group">
                              <label for="">Therapy</label>
                              <select
                                name="therapyId"
                                id=""
                                class="form-select"
                                value={editScheduleData.therapyId}
                                onChange={(e) => onChangeValue(e)}
                              >
                                <option value="0">Select Therapy</option>
                                {programs.length > 0 &&
                                  programs.map((therapy, index) => (
                                    <option key={index} value={therapy._id}>
                                      {therapy.therapyName}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col">
                            <div class="form-group ">
                              <label for="">Start Time</label>
                              <Space
                                direction="vertical"
                                style={{
                                  width: "100%",
                                  borderColor: "transparent",
                                }}
                              >
                                <TimePicker
                                  use12Hours
                                  format="h:mm A"
                                  value={
                                    dayjs(
                                      editScheduleData.startTime,
                                      "h:mm A"
                                    ).isValid()
                                      ? dayjs(
                                          editScheduleData.startTime,
                                          "h:mm A"
                                        )
                                      : null
                                  }
                                  onChange={(time, timeString) =>
                                    onChangeTimePicker(
                                      time,
                                      timeString,
                                      "startTime"
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    borderColor: "transparent",
                                    outline: "none",
                                    boxShadow: "0 0 0 transparent",
                                  }}
                                  className="form-control"
                                  placeholder="Select Start Time"
                                />
                              </Space>
                            </div>
                          </div>
                          <div className="col">
                            <div class="form-group">
                              <label for="">End Time</label>
                              <Space
                                direction="vertical"
                                style={{
                                  width: "100%",
                                  borderColor: "transparent",
                                }}
                              >
                                <TimePicker
                                  use12Hours
                                  format="h:mm A"
                                  value={
                                    dayjs(
                                      editScheduleData.endTime,
                                      "h:mm A"
                                    ).isValid()
                                      ? dayjs(
                                          editScheduleData.endTime,
                                          "h:mm A"
                                        )
                                      : null
                                  }
                                  onChange={(time, timeString) =>
                                    onChangeTimePicker(
                                      time,
                                      timeString,
                                      "endTime"
                                    )
                                  }
                                  style={{
                                    width: "100%",
                                    borderColor: "transparent",
                                    outline: "none",
                                    boxShadow: "0 0 0 transparent",
                                  }}
                                  className="form-control"
                                />
                              </Space>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label for="">Description</label>
                            <textarea
                              className="form-control"
                              name="description"
                              id="description"
                              rows="4"
                              value={editScheduleData.description}
                              onChange={(e) => onChangeValue(e)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="mx-auto text-center">
                          <button onClick={handleCancel} className="cancel">
                            Cancel
                          </button>
                          <button onClick={handleOk} className="ok">
                            Submit
                          </button>
                        </div>
                      </div>
                    </Modal>{" "} */}
                  </>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default DateScheduleInAdmin;
