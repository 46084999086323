import React, { useState, useEffect, Fragment } from "react";
import { Form } from "react-bootstrap";
import { useContext } from "react";
import dayjs from "dayjs";
import * as Bs from "react-icons/bs";
import { DatePicker, TimePicker, Space } from "antd";
import { AppContext } from "../../context/AppContext";
import { confirmAlert } from "react-confirm-alert";
import toast, { Toaster } from "react-hot-toast";
import { Button, Modal } from "antd";
import { MoonLoader } from "react-spinners";

export default function SchedulePendingList(props) {
  const { programs, deleteWeeklySchedule, deleteLoading, therapists } =
    useContext(AppContext);

  const [daySlots, setDaySlots] = useState([]);
  const [editedValues, setEditedValues] = useState({
    startTime: "N/A",
    endTime: "N/A",
    therapyId: "N/A",
    description: "N/A",
    therapistId: "N/A",
  });
  useEffect(() => {
    if (props.scheduleList && props.scheduleList.length > 0) {
      const firstSchedule = props.scheduleList[0];
      setEditedValues({
        startTime: firstSchedule.startTime || "N/A",
        endTime: firstSchedule.endTime || "N/A",
        therapyId: firstSchedule.therapyId || "N/A",
        description: firstSchedule.description || "N/A",
        // therapistId: props.slotSchedule.therapistDetails || "N/A",
      });
    }
  }, [props.scheduleList]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const weeklyScheduleDelete = (id, index) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure you want to Delete this Weekly Schedule!",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleSchedule(id, index),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const onChangeTimePicker = (time, timeString, type) => {
    switch (type) {
      case "startTime":
        setEditedValues((prev) => ({ ...prev, startTime: timeString }));
        return;
      case "endTime":
        setEditedValues((prev) => ({ ...prev, endTime: timeString }));
        return;
      default:
        return;
    }
  };

  const handleSchedule = async (id, index) => {
    const payload = {
      scheduleId: props.scheduleId,
      day: props.weekName,
      scheduleDayId: id,
    };

    await deleteWeeklySchedule(payload, {
      onSuccess: (res) => {
        console.log("res", res);
        setTimeout(() => {
          window.location.reload();
        });
      },
      onFailed: (err) => {
        console.log("error", err);
      },
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const checkIfInclude = (val) => {
    return daySlots.includes(val);
  };

  const handleDayClick = (value) => {
    if (checkIfInclude(value)) {
      const result = daySlots.filter((day) => day !== value);
      setDaySlots(result);
    } else {
      setDaySlots([value]);
    }
  };
  const onChnageTherapist = (value) => {
    const therapistId = props.slotSchedule.therapistDetails?.id;
    setEditedValues((prev) => ({ ...prev, therapistId: therapistId }));
  };
  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setEditedValues((prev) => ({ ...prev, [name]: value }));
  };

  console.log("data", props.scheduleList);
  return (
    <>
      {props.scheduleList &&
        props.scheduleList &&
        props.scheduleList.length > 0 && (
          <li class="card-data">
            <div>
              <h3 class="card-title week_title">{props.weekName}</h3>
              {props.scheduleList.map((schedule, index) => {
                const therapyData = programs.find(
                  (program) => program._id == schedule.therapyId
                );
                const therapist = props.slotSchedule.therapistDetails;

                return (
                  <div class="card-content sprd-data">
                    <p>{therapyData?.therapyName ?? "N/A"}</p>
                    <p>{therapist?.name ?? "N/A"}</p>
                    <p style={{ fontSize: "12px" }}>
                      {schedule?.startTime ?? "N/A"} to{" "}
                      {schedule?.endTime ?? "N/A"}
                    </p>
                    {/* <p>
                      {therapyData?.status == "enabled" ? (
                        <button
                          className="approve"
                          onClick={() =>
                            props.onClickApprove(schedule, therapist)
                          }
                        >
                          {" "}
                          <Bs.BsCheckAll />{" "}
                        </button>
                      ) : (
                        <p>Therapy Disabled</p>
                      )}
                    </p> */}

                    <div className="col text-center mt-3">
                      <div className="d-flex justify-content-between">
                        <div>
                          <button
                            className="week-del"
                            onClick={() =>
                              weeklyScheduleDelete(schedule._id, index)
                            }
                            disabled={deleteLoading}
                          >
                            <Bs.BsTrash />
                          </button>
                        </div>
                        {/* <div>
                          <button className="week-del" onClick={showModal}>
                            <Bs.BsPencil />
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </li>
        )}
      <>
        <Modal open={isModalOpen} closable={false}>
          <div className="container">
            <h4 className="text-center mb-3">Edit Weekly Schedule</h4>

            <div>
              <div class="card duration-card schedule_card mx-auto text-center">
                <Fragment>
                  <div className="mx-auto text-center">
                    <div class="form-group">
                      <h6 className="">Change week Days for schedule</h6>
                      <div className="week-days mt-1">
                        <div
                          className={
                            checkIfInclude("Sunday") ? "day select" : "day"
                          }
                          name="sun"
                          onClick={() => handleDayClick("Sunday")}
                        >
                          S
                        </div>
                        <div
                          className={
                            checkIfInclude("Monday") ? "day select" : "day"
                          }
                          name="mon"
                          onClick={() => handleDayClick("Monday")}
                        >
                          M
                        </div>
                        <div
                          className={
                            checkIfInclude("Tuesday") ? "day select" : "day"
                          }
                          name="tue"
                          onClick={() => handleDayClick("Tuesday")}
                        >
                          T
                        </div>
                        <div
                          className={
                            checkIfInclude("Wednesday") ? "day select" : "day"
                          }
                          name="wed"
                          onClick={() => handleDayClick("Wednesday")}
                        >
                          W
                        </div>
                        <div
                          className={
                            checkIfInclude("Thursday") ? "day select" : "day"
                          }
                          name="thu"
                          onClick={() => handleDayClick("Thursday")}
                        >
                          T
                        </div>
                        <div
                          className={
                            checkIfInclude("Friday") ? "day select" : "day"
                          }
                          name="fri"
                          onClick={() => handleDayClick("Friday")}
                        >
                          F
                        </div>
                        <div
                          className={
                            checkIfInclude("Saturday") ? "day select" : "day"
                          }
                          name="sat"
                          onClick={() => handleDayClick("Saturday")}
                        >
                          S
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div class="form-group">
                  <label for="">Therapy</label>
                  <select
                    name="therapyId"
                    id=""
                    class="form-select"
                    value={editedValues.therapyId}
                    onChange={(e) => onChangeValue(e)}
                  >
                    <option value="0">Select Therapy</option>
                    {programs.length > 0 &&
                      programs.map((therapy, index) => (
                        <option key={index} value={therapy._id}>
                          {therapy.therapyName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {/* <div className="col">
                <div class="form-group">
                  <label for="">Therapist</label>
                  <select
                    name="therapyId"
                    id=""
                    class="form-select"
                    value={editedValues.therapistId}
                    onChange={(e) => onChnageTherapist(e)}
                  >
                    <option value="0" selected>
                      Select Therapist
                    </option>
                    {therapists.length > 0 &&
                      therapists.map((therapist, index) => {
                        // console.log(therapy);
                        return (
                          <>
                            <option value="0">Select Therapist</option>
                            {therapists.length > 0 &&
                              therapists.map((therapist, index) => (
                                <option key={index} value={therapist._id}>
                                  {therapist.firstName +
                                    " " +
                                    therapist.lastName}
                                </option>
                              ))}
                          </>
                        );
                      })}
                  </select>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div class="col">
                <div class="form-group ">
                  <label for="">Start Time</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      borderColor: "transparent",
                    }}
                  >
                    <TimePicker
                      use12Hours
                      format="h:mm A"
                      value={
                        dayjs(editedValues.startTime, "h:mm A").isValid()
                          ? dayjs(editedValues.startTime, "h:mm A")
                          : null
                      }
                      onChange={(time, timeString) =>
                        onChangeTimePicker(time, timeString, "startTime")
                      }
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                        outline: "none",
                        boxShadow: "0 0 0 transparent",
                      }}
                      className="form-control"
                      placeholder="Select Start Time"
                    />
                  </Space>
                </div>
              </div>
              <div className="col">
                <div class="form-group">
                  <label for="">End Time</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      borderColor: "transparent",
                    }}
                  >
                    <TimePicker
                      use12Hours
                      format="h:mm A"
                      value={
                        dayjs(editedValues.endTime, "h:mm A").isValid()
                          ? dayjs(editedValues.endTime, "h:mm A")
                          : null
                      }
                      onChange={(time, timeString) =>
                        onChangeTimePicker(time, timeString, "endTime")
                      }
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                        outline: "none",
                        boxShadow: "0 0 0 transparent",
                      }}
                      className="form-control"
                    />
                  </Space>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label for="">Description</label>
                <textarea
                  className="form-control"
                  name="description"
                  id="description"
                  rows="4"
                  value={editedValues.description}
                  onChange={(e) => onChangeValue(e)}
                ></textarea>
              </div>
            </div>
            <div className="mx-auto text-center">
              <button onClick={handleCancel} className="cancel">
                Cancel
              </button>
              <button onClick={handleOk} className="ok">
                Submit
              </button>
            </div>
          </div>
        </Modal>{" "}
      </>
    </>
  );
}
