import React, { useEffect, useState, useContext } from "react";
import "./ScheduleData.css";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import { AppContext } from "../../context/AppContext";
import Utils from "../../Utils/Utils";
import { confirmAlert } from "react-confirm-alert";
import { Modal } from "antd";
import toast, { Toaster } from "react-hot-toast";

function DateSchedulePendingList() {
  const {
    pendingDateScheduleList,
    programs,
    approveSingleDateSchedule,
    deleteSchedule,
    getPendingDateSchedule,
    getListOfScheduledSlots,
  } = useContext(AppContext);

  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [price, setPrice] = useState("");
  const [error, setError] = useState(false);
  const [approveId, setApproveId] = useState(null);
  const [preview, setPreview] = useState(null);
  const [therapistSchedule, setTherapistSchedule] = useState([]);

  const findTherapyDataById = (id) => {
    return programs.find((x) => x._id == id);
  };

  const approveTherapy = async () => {
    if (price.length == 0) {
      setError(true);
      return;
    }
    const payload = {
      price,
      approved: true,
    };

    await approveSingleDateSchedule(approveId, payload, {
      onSuccess: async (res) => {
        toast.success("Schedule is Approved SuccessFully");
        await getPendingDateSchedule();
        setTimeout(() => {
          setPrice("");
          setShowScheduleModal(false);
        }, 2000);
      },
      onFailed: (error) => {
        toast.error("Approve Failed");
        console.log(error);
      },
    });
  };
  const DeleteSchedule = (id) => {
    confirmAlert({
      title: "Confirmation",
      message: "Are you sure you want to Delete this Schedule!",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleSchedule(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleSchedule = async (id) => {
    console.log(id);
    await deleteSchedule(id, {
      onSuccess: async (res) => {
        console.log(id);
        toast.success(res.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <>
      <Modal
        open={showScheduleModal}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setShowScheduleModal(false);
          setPrice("");
          setError(false);
        }}
        centered
      >
        <h3>Add Price to Schedule</h3>
        <p>{/* Therapy Name: <span>{therapyName}</span> */}</p>
        <p>
          Price:{" "}
          <input
            type="text"
            className="price form-control"
            value={price}
            placeholder={"Enter price"}
            onChange={(e) => setPrice(e.target.value)}
          />
          {error ? (
            <span style={{ color: "red" }}>Price connot be blank</span>
          ) : (
            ""
          )}
        </p>
        <button class="approve_btn" onClick={approveTherapy}>
          Approve
        </button>
        {/* <p>Therapist: }</p> */}
      </Modal>
      <div className="">
        <div className="row mx-auto">
          {pendingDateScheduleList &&
            pendingDateScheduleList != null &&
            pendingDateScheduleList.map((list, key) => {
              const scheduleData = list.schedules;
              const therapist = list.therapistDetails;

              return (
                <>
                  <div className="col-md-4">
                    {scheduleData &&
                      scheduleData.length > 0 &&
                      scheduleData.map((schedule) => {
                        return (
                          <div class="m-0">
                            <div class="card date-card" id="date-pending-list">
                              <h1>
                                {schedule.therapyId != null
                                  ? findTherapyDataById(schedule.therapyId)
                                      ?.therapyName
                                  : "N/A"}
                              </h1>
                              <div class="d-flex">
                                <p>
                                  <i>
                                    <Ai.AiOutlineCalendar />
                                  </i>{" "}
                                  <span>{Utils.formatDate(list.date)}</span>
                                </p>
                                <p className="mx-3">
                                  <i>
                                    <Ai.AiOutlineClockCircle />
                                  </i>{" "}
                                  <span>{`${schedule?.startTime}-${schedule?.endTime}`}</span>
                                </p>
                              </div>
                              <p className="date-cont">
                                <i>
                                  <Ai.AiOutlineUser />
                                </i>{" "}
                                {therapist?.name ?? "N/A"}
                              </p>
                              <p className="date-cont pb-2">
                                <i>
                                  <Ai.AiOutlineMail />
                                </i>{" "}
                                {therapist?.email ?? "N/A"}
                              </p>

                              <p className="date-cont" id="date-desc">
                                {schedule?.description ?? "N/A"}
                              </p>
                              {/* {!schedule.price && (
                              <button
                                class="approve"
                                id="date-approve"
                                title="Approve"
                                onClick={() => {
                                  setShowScheduleModal(true);
                                  setApproveId(schedule._id);
                                }}
                              >
                                <Bs.BsCheckAll />
                              </button>
                            )} */}

                              <div className="col text-center mt-3">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <button
                                      className="delete-sch"
                                      onClick={() =>
                                        DeleteSchedule(schedule._id)
                                      }
                                    >
                                      <Bs.BsTrash />
                                    </button>
                                  </div>
                                  {/* <div>
                                    <button className="week-del">
                                      <Bs.BsPencil />
                                    </button>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default DateSchedulePendingList;
