import React from "react";
import toast, { Toaster } from "react-hot-toast";
import "../../Pages/Broadcast/settings.css";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
function GuestJoin() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { guestLoading, verifyGuest } = useContext(AppContext);
  const [otp, setOtp] = useState("");
  const [booking, setBooking] = useState("");
  const [initialState, setInitialState] = useState({
    name: "",
  });
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("guestValues"));
    console.log("data", data);
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setInitialState((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    const payload = {
      securityCode: otp,
      name: initialState.name,
      guestId: id,
    };
    await verifyGuest(payload, {
      onSuccess: async (res) => {
        toast.success("Guest Verified!");
        setTimeout(() => {
          navigate(`/therapy-session/${res.bookingId}`);
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };
  return (
    <>
      <Toaster position="top-center" />

      <div className="security_Cont">
        <h2 className="text-center mx-auto part">Guest Participation</h2>
        <div className="loginForm">
          <div className="logo">
            <img src={require("../../assets/logo_main.png")} />
          </div>
          <br />
          <div className="container">
            <form onSubmit={(e) => handleVerification(e)}>
              <div className="form-group mb-3">
                <label htmlFor="" className="form-label review-label">
                  Name
                </label>
                <input
                  name="name"
                  className="form-control"
                  placeholder="Enter Name of the Participant"
                  value={initialState.name}
                  onChange={handleChange}
                />
              </div>
              <label htmlFor="" className="form-label review-label">
                Enter Security Code
              </label>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => (
                  <input {...props} className="security" />
                )}
              />
              <button type="submit" className="login_btn">
                Verify
              </button>
            </form>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default GuestJoin;
