import BaseClient from "../Helper/BaseClient";
import { useState } from "react";
import { APIEndpoints } from "../constants/APIEndpoints";

const useTherapist = () => {
  const [actionLoading, setActionLoading] = useState({
    action: "",
    loading: false,
  });
  /* update details */
  const editInstructor = async (data, id, { onSuccess, onFailed }) => {
    try {
      setActionLoading({ action: "Edit", loading: true });

      await BaseClient.put(APIEndpoints.editInstructor + `/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setActionLoading({ action: "", loading: false });
    }
  };
  const deleteInstructorDatas = async (id, { onSuccess, onFailed }) => {
    try {
      setActionLoading(true);

      await BaseClient.delete(APIEndpoints.deleteInstructor + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setActionLoading(false);
    }
  };
  const DeclineRescheduleRequest = async (payload, { onSuccess, onFailed }) => {
    try {
      setActionLoading({ action: " Add", loading: true });
      await BaseClient.post(APIEndpoints.declineRequest, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setActionLoading(false);
    }
  };
   const acceptRequest = async (
     payload,
     { onSuccess, onFailed }
   ) => {
     try {
       setActionLoading({ action: " Add", loading: true });
       await BaseClient.post(APIEndpoints.acceptRequestFromClient, payload, {
         onSuccess: onSuccess,
         onFailed: onFailed,
       });
     } finally {
       setActionLoading(false);
     }
   };
  return {
    editInstructor,
    actionLoading,
    deleteInstructorDatas,
    DeclineRescheduleRequest,
    acceptRequest,
  };
};
export default useTherapist;
