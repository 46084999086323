import React, { useState, useEffect, useContext } from "react";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import * as Bs from "react-icons/bs";
import ReactPaginate from "react-paginate";
import "./Users.css";
import { confirmAlert } from "react-confirm-alert";

function Users() {
  const navigate = useNavigate();
  const { registerdUsers, deleteUser, getRegisteredUsers } =
    useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const manageItems = registerdUsers.slice(indexOfFirstItem, indexOfLastItem);
  console.log(registerdUsers);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const DeleteUser = (id) => {
    confirmAlert({
      title: "Confirmation",
      message:
        "Are you sure you want to proceed? This action will permanently remove the user.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteUser(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDeleteUser = async (id) => {
    if (!id) return;

    await deleteUser(id, {
      onSuccess: async (res) => {
        toast.success(res.message);
        await getRegisteredUsers();
      },
      onFailed: (err) => {
        toast.error(err);
      },
    });
  };
  console.log(registerdUsers);
  return (
    <>
      <Header />
      <Toaster position="top-center" />
      <main>
        <button className="Add" onClick={() => navigate("/add-user")}>
          Add
        </button>
        <section class="main ml-3 pl-4">
          <h2>Clients</h2>
          <div></div>
          <div class="card user_card">
            <table class="table table-bordered">
              <thead style={{ textAlign: "center" }}>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Registered Date</th>
                  <th>Edit</th>
                  <th>Delete</th>
                  {/* <th>verification</th> */}
                </tr>
              </thead>
              <tbody>
                {manageItems.length > 0 &&
                  manageItems.map((users, index) => {
                    console.log(users);
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }} key={index}>
                          {index + 1}
                        </td>
                        <td>{users.name ?? "N/A"}</td>
                        <td>{users.email ?? "N/A"}</td>
                        <td style={{ textAlign: "center" }}>
                          {users?.phonenumber ?? "N/A"}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {users?.registration_date ?? "N/A"}
                        </td>
                        <td className="text-center">
                          <button
                            className="edit-user"
                            onClick={() => {
                              navigate(`/edit-user/${users._id}`);
                            }}
                          >
                            <i>
                              <Bs.BsPencilFill />
                            </i>
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            className="delete-user"
                            onClick={() => DeleteUser(users._id)}
                          >
                            <i>
                              <Bs.BsTrash />
                            </i>
                          </button>
                        </td>

                        {/* <td>
                          {users.registeration_status == "verified" ? (
                            <i className="status green">
                              <Go.GoVerified />
                            </i>
                          ) : (
                            <i className="status red">
                              <Go.GoUnverified />
                            </i>
                          )}
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="pagnte">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(registerdUsers.length / itemsPerPage)}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              disabledClassName={"disabled"}
              activeClassName={"paging__link--active"}
            />
          </div>
        </section>
      </main>
    </>
  );
}

export default Users;
