import React, { createContext, useState, useEffect } from "react";
import { io } from "socket.io-client";
import useCookies from "../hook/useCookies";

export const SocketContext = createContext();

const SOCKET_URL = process.env.REACT_APP_BASE_URL;

export default function SocketContextProvider(props) {
  const [socket, setSocket] = useState(null);

  const { getValueFromCookies } = useCookies({ key: "UserPersistent" });

  useEffect(() => {
    connectToSocket();
  }, []);

  const connectToSocket = () => {
    const user = getValueFromCookies();
    console.log(user);
    if (user != null) {
      const newSocket = io.connect(SOCKET_URL, {
        query: { token: user.accessToken },
      });
      setSocket(newSocket);
    }
  };

  return (
    <SocketContext.Provider value={{ socket, connectToSocket }}>
      {props.children}
    </SocketContext.Provider>
  );
}
