import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Header from "../../Components/Header/Header";
import Logo from "../../assets/log_bgg.png";
import main from "../../assets/logo_main.png";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Audio } from "react-loader-spinner";
import * as Ai from "react-icons/ai";

function AddUser() {
  const navigate = useNavigate();
  const { registerUser, getRegisteredUsers } = useContext(AppContext);
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);

  const [initialState, setInitialState] = useState({
    name: "",
    email: "",
    password: "",
    phonenumber: "",
  });
  const handleChange = (e) => {
    const { name, value } = e;

    setInitialState((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handlePhoneNumber = (e) => {
    const number = e.target.value.replace(/[^\d+\-*/]/g, "");
    setInitialState((prev) => ({ ...prev, phonenumber: number }));
  };

  useEffect(() => {
    const generateRandomPassword = () => {
      const randomPassword = Math.floor(100000 + Math.random() * 900000);
      setInitialState((prev) => ({
        ...prev,
        password: String(randomPassword),
      }));
    };
    generateRandomPassword();
  }, []);

  const formSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (
      initialState.name.length === 0 ||
      initialState.email.length === 0 ||
      initialState.password.length === 0
    ) {
      setError(true);
      setLoad(false);
    } else {
      setError(false);
      await registerUser(initialState, {
        onSuccess: async (res) => {
          setLoad(false);
          toast.success("Successfully Created User!");
          setInitialState({
            fullName: "",
            email: "",
            password: "",
            phonenumber: "",
          });
          setError(false);

          await getRegisteredUsers();
          setTimeout(() => {
            navigate("/client");
          }, 1000);
        },
        onFailed: (err) => {
          console.log(err.message);
          toast.error(err.message);
          setLoad(false);
        },
      });
    }
  };

  return (
    <>
      <Header />
      <Toaster position="top-center" />
      <main>
        <section className="main pl-3">
          <button
            className="back_btn"
            style={{ position: "absolute" }}
            onClick={() => navigate(-1)}
          >
            <Ai.AiOutlineLeft />
          </button>
          <div className="add-client">
            <div className="mx-auto">
              <div className="row">
                <div className="col-md-8 mx-auto edit-client">
                  <div className="card p-3">
                    <div className="">
                      <div className="col-md-12">
                        <div className="col-md-10 mx-auto">
                          <div className="mx-auto text-center"></div>
                          <form
                            className="client-form"
                            onSubmit={(e) => formSubmit(e)}
                          >
                            <h5 className="text-center add-c mt-3">
                              Add Client
                            </h5>
                            <div className="col">
                              <div className="form-group">
                                <label for="program name" className="p-1">
                                  Full name
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter Your Name"
                                  name="name"
                                  className="form-control"
                                  value={initialState?.name}
                                  onChange={(e) => handleChange(e.target)}
                                />
                                {initialState?.name != null &&
                                  initialState?.name.length == 0 &&
                                  error && (
                                    <span className="err_msg">
                                      Please Enter Your Name!
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label for="program name" className="p-1">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control review-input"
                                  name="email"
                                  placeholder="Enter your Email Address"
                                  value={initialState?.email}
                                  onChange={(e) => handleChange(e.target)}
                                />

                                {initialState?.email != null &&
                                  initialState?.email?.length == 0 &&
                                  error && (
                                    <span className="err_msg">
                                    Email required
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label htmlFor="program name" className="p-1">
                                  Phone
                                </label>
                                <input
                                  type="text"
                                  className="form-control review-input"
                                  name="phonenumber"
                                  placeholder="Enter your Phone number"
                                  value={initialState?.phonenumber}
                                  onChange={(e) => handlePhoneNumber(e)}
                                />
                                {error &&
                                  initialState?.phonenumber.length === 0 && (
                                    <div className="err-msg">
                                      Invalid phone number.
                                    </div>
                                  )}
                              </div>
                            </div>
                            {/* <div className="col">
                              <div className="form-group">
                                <label for="program name" className="p-1">
                                  Password
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter Your Password"
                                  name="password"
                                  value={initialState?.password}
                                  onChange={(e) => handleChange(e.target)}
                                  className="form-control"
                                />
                                {initialState?.password != null &&
                                  initialState?.password.length == 0 &&
                                  error && (
                                    <span className="err_msg">
                                      Enter Password!
                                    </span>
                                  )}
                              </div>
                            </div> */}
                            {load ? (
                              <div className="col text-center">
                                <button
                                  type="submit"
                                  className="form_btn res"
                                  disabled
                                >
                                  Submitting
                                </button>
                              </div>
                            ) : (
                              <div className="col text-center">
                                <button type="submit" className="form_btn ">
                                  Submit
                                </button>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AddUser;
