import React, { useEffect, useState } from "react";
import { Space, TimePicker } from "antd";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { useContext } from "react";

import toast from "react-hot-toast";
import { AppContext } from "../../context/AppContext";

function DateSchedule({ selectedDate, formattedDate }) {
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const initialValues = {
    startTime: null,
    endTime: null,
    startTimeValue: null,
    endTimeValue: null,
    therapyId: null,
    description: null,
  };

  const [scheduleSlots, setScheduleSlots] = useState([initialValues]);

  const { programs, scheduleLoading, scheduleByDate, getDateScheduleData } =
    useContext(AppContext);

  const addNewSchedule = () => {
    setScheduleSlots([...scheduleSlots, initialValues]);
  };

  const deleteSchedule = (deleteIndex) => {
    const slots = [];
    scheduleSlots.forEach((slot, index) => {
      if (index !== deleteIndex) {
        slots.push(slot);
      }
    });
    setScheduleSlots(slots);
  };

  const onChangeTimePicker = (time, timeString, index, type) => {
    setError(false);

    setScheduleSlots((prevSlots) => {
      const newSlots = [...prevSlots];
      switch (type) {
        case "startTime":
          newSlots[index] = {
            ...newSlots[index],
            startTime: timeString,
            startTimeValue: time,
          };
          break;
        case "endTime":
          newSlots[index] = {
            ...newSlots[index],
            endTime: timeString,
            endTimeValue: time,
          };
          break;
        default:
          break;
      }
      return newSlots;
    });
  };

  const onChangeDescription = (index, value) => {
    scheduleSlots[index].description = value;
    setScheduleSlots([...scheduleSlots]);
  };

  const handleDateSchedule = async (e) => {
    e.preventDefault();

    if (!selectedDate) {
      toast.error("Please Select a Date");
      return;
    }

    let hasError = false;

    scheduleSlots.forEach((slot) => {
      setError(false);

      if (!slot.startTimeValue || !slot.endTimeValue) {
        toast.error("Please set both start and end times.");
        hasError = true;
        return;
      }
      if (slot.endTimeValue.isSameOrBefore(slot.startTimeValue)) {
        toast.error("End time must be greater than start time.");
        hasError = true;
        return;
      }
      console.log(formattedDate, slot.startTimeValue);
      if (
        formattedDate === moment().format("YYYY-MM-DD") &&
        slot.startTimeValue.isBefore(moment())
      ) {
        toast.error(
          "Start time must be greater than the current time for today's date."
        );
        hasError = true;
        return;
      }
      return;
    });

    if (hasError) {
      setError(true);
      return;
    }

    const payload = {
      date: selectedDate,
      therapistId: params.id,
      scheduleDetails: scheduleSlots,
    };
    console.log(payload);
    await scheduleByDate(payload, {
      onSuccess: async (res) => {
        toast.success(res.message);
        await getDateScheduleData();
        setTimeout(() => {
          navigate("/schedule");
        }, 1500);
      },
      onFailed: (err) => {
        toast.error(err.message || "Something Went Wrong!");
      },
    });
  };

  return (
    <>
      <form onSubmit={handleDateSchedule}>
        {scheduleSlots.map((schedule, index) => {
          return (
            <div className="card inst_card schedule_card" key={index}>
              <h5> #{index + 1}</h5>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Therapy</label>
                    <span style={{ color: "red" }}>*</span>
                    <select
                      name="therapyId"
                      className="form-select"
                      value={schedule.therapyId}
                      onChange={(e) => {
                        const value = e.target.value;
                        setScheduleSlots((prev) =>
                          prev.map((slot, idx) =>
                            idx === index ? { ...slot, therapyId: value } : slot
                          )
                        );
                      }}
                    >
                      <option value="0">Select Therapy</option>
                      {programs.length > 0 &&
                        programs.map((therapy, idx) => {
                          return (
                            <option key={idx} value={therapy._id}>
                              {therapy.therapyName}
                            </option>
                          );
                        })}
                    </select>
                    {error && !schedule.therapyId && (
                      <span id="err">Please Select a therapy</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Start Time</label>
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="time"
                      onChange={(e) => {
                        const timeString = e.target.value;
                        const time = moment(timeString, "HH:mm");
                        onChangeTimePicker(
                          time,
                          timeString,
                          index,
                          "startTime"
                        );
                      }}
                      value={
                        schedule.startTimeValue
                          ? schedule.startTimeValue.format("HH:mm")
                          : ""
                      }
                      className="form-control"
                      placeholder="Select Start Time"
                    />
                    {error && !schedule.startTime && (
                      <span id="err">Please Set the Start Time</span>
                    )}
                  </div>
                  <div className="form-group mt-2">
                    <label htmlFor="">End Time</label>
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="time"
                      onChange={(e) => {
                        const timeString = e.target.value;
                        const time = moment(timeString, "HH:mm");
                        onChangeTimePicker(time, timeString, index, "endTime");
                      }}
                      value={
                        schedule.endTimeValue
                          ? schedule.endTimeValue.format("HH:mm")
                          : ""
                      }
                      className="form-control"
                      placeholder="Select End Time"
                    />
                    {error && !schedule.endTime && (
                      <span id="err">Please Set the End Time</span>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4 col-md-4">
                  <label htmlFor="">Description</label>
                  <textarea
                    className="form-control"
                    rows="4"
                    onChange={(e) => {
                      const value = e.target.value;
                      onChangeDescription(index, value);
                    }}
                    value={schedule.description}
                  ></textarea>
                </div>
              </div>
              {index !== 0 && scheduleSlots.length > 1 && (
                <button
                  className="delete_schedule"
                  onClick={() => deleteSchedule(index)}
                >
                  Delete
                </button>
              )}
            </div>
          );
        })}

        <button type="button" className="add_schedule" onClick={addNewSchedule}>
          +
        </button>

        <button type="submit" className="form_btn mb-4">
          {scheduleLoading.loading === true ? "Adding..." : "Add"}
        </button>
      </form>
    </>
  );
}

export default DateSchedule;
