import React from "react";
import Header from "../../Components/Header/Header";
import "./Settings.css";
import "react-tabs/style/react-tabs.css";
import { FcStumbleupon } from "react-icons/fc";
import { Row, Container } from "react-bootstrap";
import { FcElectronics } from "react-icons/fc";
import { FcTemplate } from "react-icons/fc";
import { useNavigate, useParams } from "react-router-dom";

function Settings() {
  const navigate = useNavigate();
  return (
    <>
      <header>
        <Header />
      </header>
      <Container>
        <main>
          {/* <section className="main">
        <h1 className="admin_pro1">Settings</h1>
        <Tabs tabPosition="right" vertical>
          <TabList>
            <Tab>
              <p>Profile</p>
            </Tab>
            <Tab>
              <p>Privacy</p>
            </Tab>
          </TabList>
          <TabPanel>
            <div className="panel-content">
              <h2 className="admin_pro">Admin Profile</h2>

              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <div className="card py-4">
                      <div className="text-center">
                        <img
                          src={placeholder}
                          width="100"
                          className="rounded-circle"
                        />
                      </div>

                      <div className="text-center mt-3">
                        <span className="bg-secondary p-1 px-4 rounded text-white">
                          Pro
                        </span>
                        <h5 className="mt-2 mb-0">Alexender Schidmt</h5>
                        <span>Admin</span>

                        <div className="px-4 mt-1">
                          <p className="fonts">
                            Consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna exercitation
                            ullamco laboris nisi ut aliquip ex ea commodo
                            consequat.{" "}
                          </p>
                        </div>
                          <button className="btn btn-success me-2">OK</button>
                          <button className="btn btn-danger"><Ai.AiOutlineClose/></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="panel">
              <h2 className="admin_pro">Privacy & Policy</h2>
            </div>
          </TabPanel>
        </Tabs>
      </section> */}
          <section class="main">
            <h4>Admin Settings</h4>
            <div className="new-set">
              <div className="d-flex">
                <div className="icon-circle">
                  <i className="icons-div">
                    <FcElectronics />
                  </i>

                  <button
                    className="naviagte-menu"
                    onClick={() => navigate("/therapy")}
                  >
                    Manage Therapies
                  </button>
                </div>
              </div>
            </div>
            <div className="new-set">
              <div className="d-flex">
                <div className="icon-circle">
                  <i className="icons-div">
                    <FcStumbleupon />
                  </i>

                  <button
                    className="naviagte-menu"
                    onClick={() => navigate("/category")}
                  >
                    Categories
                  </button>
                </div>
              </div>
            </div>
            <div className="new-set">
              <div className="d-flex">
                <div className="icon-circle">
                  <i className="icons-div">
                    <FcTemplate />
                  </i>

                  <button
                    className="naviagte-menu"
                    onClick={() => navigate("/schedule")}
                  >
                    Schedule Management
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="btn-setting">
              <div className="d-flex card-btn">
                <div className="card">
                  <button className="admin-btn" onClick={() => navigate("/therapy")} >Manage Therapies</button>
                </div>
                <div className="card">
                  <button className="admin-btn" onClick={() => navigate("/category")} >Categories</button>
                </div>
                <div className="card">
                  <button className="admin-btn" onClick={() => navigate("/schedule")} >Schedule Management</button>
                </div>
              </div>
            </div> */}
          </section>
        </main>
      </Container>
    </>
  );
}

export default Settings;
