import React, { useState, useContext, useEffect } from "react";
import Table from "react-bootstrap/Table";
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { Row, Col, Container } from "react-bootstrap";
import * as Md from "react-icons/md";
import "./Therapy.css";
import Header from "../../Components/Header/Header";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import toast, { Toaster } from "react-hot-toast";
import * as Fa from "react-icons/fa";
import { BeatLoader } from "react-spinners";
import TherapyPlaceholder from "../../assets/placeholder-img.png";

function Therapy() {
  const navigate = useNavigate();
  const { programs, categories, popularizeTherapy, deleteTherapy } =
    useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [therapyName, setTherapyName] = useState("");
  const [therapyDescription, setTherapyDescription] = useState("");
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [therapyData, setTherapyData] = useState(null);
  const [singleTherapy, setSingleTherapy] = useState(null);
  const [addLoading, setAddLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = programs.slice(indexOfFirstItem, indexOfLastItem);
  const { getTherapy, addTherapy, enableTherapySingle, disableTherapySingle } =
    useContext(AppContext);

  const onClickShowModal = () => {
    setShowModal(true);
  };
  const showModalData = (program) => {
    setOpenModal(true);
    setTherapyData(program);
  };

  const formSubmit = async (e) => {
    e.preventDefault();

    if (therapyName?.length === 0 || !selectedCategoryIndex || !image) {
      setError(true);
      toast.error("Please update all Mandatory Fields!");
      return false;
    }

    const formData = new FormData();

    formData.append("file", image);
    formData.append("therapyName", therapyName);
    formData.append("therapyDescription", therapyDescription);
    formData.append("categoryId", categories[selectedCategoryIndex]._id);

    setAddLoading(true);
    await addTherapy(formData, {
      onSuccess: async () => {
        setShowModal(false);
        toast.success("Therapy Added Successfully!");
        setAddLoading(false);
        await getTherapy();
        setTimeout(() => {
          window.location.reload();
        });
      },
      onFailed: (e) => {
        toast.error("Something Went Wrong!");
        setAddLoading(false);
      },
    });
  };
  const handleLogout = () => {
    setIsLoading(false);

    setTherapyName("");
    setSelectedCategoryIndex("");
    setSelectedCategoryIndex("");
    setTherapyDescription("");
    setImage(null);

    setShowModal(false);
  };

  const submitPrev = () => {
    confirmAlert({
      message: "Are you sure you want to go back?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleLogout(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleEnableDisableTherapy = (id) => {
    const singleTherapy = programs.filter((x) => x._id == id);

    if (singleTherapy[0].status == "disabled") {
      enableTherapySingle(id, {
        onSuccess: async () => {
          toast.success("Therapy Enabled");
          await getTherapy();
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } else if ((singleTherapy[0].status = "enabled")) {
      disableTherapySingle(id, {
        onSuccess: async () => {
          toast.success("Therapy Disabled");
          await getTherapy();
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    }
  };

  const enableDiableTherapy = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "This action cause therapy's process",
      buttons: [
        {
          className: "confirm-btn",
          label: "Yes",
          onClick: () => handleEnableDisableTherapy(id),
        },
        {
          className: "clear-button",
          label: "No",
        },
      ],
    });
  };
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handlePopularize = async (id, popular) => {
    const payload = {
      therphyId: id,
      popular: !popular,
    };

    console.log("payload data", payload);

    await popularizeTherapy(payload, {
      onSuccess: async (res) => {
        toast.success(res.message);
        await getTherapy();
      },
      onFailed: (err) => {
        toast.error(err);
      },
    });
  };
  const ConfirmDelete = (id) => {
    confirmAlert({
      title: "Confirmation",
      message:
        "This action will permanently remove Therapy from Database.Are you sure you want to proceed? ",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleTherapy(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleTherapy = async (id) => {
    await deleteTherapy(id, {
      onSuccess: async (res) => {
        toast.success(res.message);
        await getTherapy();
      },
      onFailed: (err) => {
        toast.error(err);
      },
    });
  };
  return (
    <>
      <Toaster position="top-center" />
      <header>
        <Header />
      </header>
      <div className={`modal_bg ${!showModal ? "cat" : "cat show"}`}>
        <div className="add_modal category_add">
          <i className="close" onClick={submitPrev}>
            <Ai.AiFillCloseCircle />
          </i>
          <h2>Add Therapy</h2>
          <form
            className="form-control add_form"
            onSubmit={(e) => formSubmit(e)}
          >
            <Row>
              <Col xl={6} sm={12}>
                <div className="form-group">
                  <label for="program name">Therapy name</label>
                  <span style={{ color: "red" }}>*</span>
                  <input
                    type="text"
                    name="therapyName"
                    className="form-control"
                    onChange={(e) => setTherapyName(e.target.value)}
                    value={therapyName}
                  />
                  {error && therapyName?.length === 0 ? (
                    <>
                      <span style={{ color: "red" }}>
                        This field cannot be blank
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col xl={6} sm={12}>
                <div className="form-group">
                  <label for="email">File</label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <input
                    type="file"
                    className="form-control add_file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                    }}
                    name="file"
                    accept="image/*"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  {/* value from add category */}
                  <label for="exp">Category</label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <select
                    className="form-select"
                    onChange={function (e) {
                      return setSelectedCategoryIndex(e.target.value);
                    }}
                    value={selectedCategoryIndex}
                  >
                    <option selected hidden>
                      Select a category
                    </option>
                    {Array.isArray(categories) &&
                      categories.length > 0 &&
                      categories.map((x, index) => {
                        return (
                          <>
                            <option value={index}>{x?.category}</option>
                          </>
                        );
                      })}
                  </select>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xl={12}>
                <label for="Bio">Description</label>{" "}
                <span style={{ color: "red" }}>*</span>
                <textarea
                  className="bio"
                  name="therapyDescription"
                  value={therapyDescription}
                  onChange={(e) => setTherapyDescription(e.target.value)}
                />
              </Col>
            </Row>
            <br />
            <button type="submit" className="form_btn" disabled={addLoading}>
              {addLoading ? <BeatLoader color="#fff" /> : "Add"}
            </button>
          </form>
        </div>
      </div>

      <main>
        <section className="main pl-3">
          <div>
            <button
              className="back_btn"
              style={{ position: "absolute" }}
              onClick={() => navigate("/settings")}
            >
              <Ai.AiOutlineLeft />
            </button>
          </div>
          <button className="add_btn " onClick={onClickShowModal}>
            +Add
          </button>
          <h1 className="therapy-head">Therapy</h1>
          <Container className="table-co">
            <div className="card inst_card mt-3 table-responsive" id="cat_card">
              <Table>
                <table role="table" className="table table-bordered user_card">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Program</th>
                      <th>Category</th>
                      <th>Description</th>
                      <th style={{ textAlign: "center" }}>Image</th>
                      <th>Popular</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems &&
                      currentItems.map((program, index) => {
                        const category =
                          program?.category != undefined &&
                          program?.category != "undefined" &&
                          program?.category;

                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{program?.therapyName ?? "N/A"}</td>
                            <td>{category?.category ?? "N/A"}</td>
                            <td>{program?.therapyDescription ?? "N/A"}</td>
                            <td className="therapy_img">
                              <div className="therapy_img_area">
                                <img
                                  src={
                                    program?.photo != null
                                      ? process.env.REACT_APP_IMAGE_URL +
                                        program.photo
                                      : TherapyPlaceholder
                                  }
                                  alt=""
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                class={
                                  program.popular
                                    ? "star_div active mx-auto"
                                    : "star_div mx-auto"
                                }
                                onClick={() =>
                                  handlePopularize(
                                    program?._id,
                                    program?.popular
                                  )
                                }
                              >
                                <Fa.FaStar />
                              </div>
                            </td>
                            <td>
                              <button
                                className="btsuc"
                                onClick={() => {
                                  navigate(`/edit-therapy/${program._id}`);
                                }}
                                singleTherapy={singleTherapy}
                              >
                                <i>
                                  <Bs.BsFillPencilFill />
                                </i>
                              </button>
                              <button
                                className={
                                  program.status === "disabled"
                                    ? "therapist_enaDis red"
                                    : "therapist_enaDis"
                                }
                                onClick={() => enableDiableTherapy(program._id)}
                              >
                                <i className="edit">
                                  {program.status === "disabled" ? (
                                    <Ai.AiFillEyeInvisible />
                                  ) : (
                                    <Md.MdDisabledVisible />
                                  )}
                                </i>
                              </button>

                              <button
                                className="delete-inst"
                                onClick={() => ConfirmDelete(program._id)}
                              >
                                <i>
                                  <Bs.BsTrash />
                                </i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Table>
            </div>
            <div className="pagnte">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={Math.ceil(programs.length / itemsPerPage)}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"disabled"}
                activeClassName={"paging__link--active"}
              />
            </div>
          </Container>
        </section>
      </main>

      <footer />
    </>
  );
}

export default Therapy;
