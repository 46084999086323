import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import "./StreamManager.css";
import moment from "moment";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import toast, { Toaster } from "react-hot-toast";
import AllStreams from "./AllStreams";
import StreamsList from "./StreamsList";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Utils from "../../Utils/Utils";

function StreamManager() {
  const navigate = useNavigate();

  const { therapistSchedules } = useContext(AppContext);

  const whenStreamStart = (streamData) => {
    const currentDateTime = moment().format("DD-MM-YYYY hh:mm A");

    if (!streamData?.date) {
      toast.error("Date is invalid, Please try again!");
      return;
    }

    const streamStartDate =
      Utils.formatDate(new Date(streamData?.date)) ??
      streamData?.date + " " + streamData?.startTime;
    console.log("liveDateAndTime", currentDateTime, streamStartDate);

    if (
      moment(currentDateTime, "DD-MM-YYYY hh:mm A").isSameOrAfter(
        moment(streamStartDate, "DD-MM-YYYY hh:mm A")
      )
    ) {
      navigate("/therapy-session/" + streamData._id);
    } else {
      toast.error(
        "Time not reached! Live will only start after reaching the start time."
      );
    }
  };

  return (
    <>
      <Header />
      <Toaster position="top-center" />
      <main>
        <section class="main">
          <h1>Session Manager</h1>
          <Tabs
            defaultActiveKey="All"
            id="justify-tab-example"
            className="mb-3 text-wrap"
            justify
          >
            <Tab eventKey="All" title="All Sessions">
              {therapistSchedules != null && therapistSchedules.schedules && (
                <StreamsList
                  streams={therapistSchedules.schedules.filter(
                    (stream) => stream.bookingStatus != "Rescheduled"
                  )}
                  therapist={therapistSchedules.therapistDetails}
                  onStreamStart={(streamData) => whenStreamStart(streamData)}
                  finished={true}
                />
              )}
            </Tab>
            <Tab eventKey="Upcoming" title="Upcoming">
              {therapistSchedules != null && therapistSchedules.schedules && (
                <StreamsList
                  streams={therapistSchedules.schedules.filter(
                    (stream) =>
                      stream.streamStatus != "STARTED" &&
                      stream.streamStatus != "STOPPED" &&
                      stream.bookingStatus != "CANCELED" &&
                      stream.bookingStatus != "Rescheduled"
                  )}
                  therapist={therapistSchedules.therapistDetails}
                  onStreamStart={(streamData) => whenStreamStart(streamData)}
                  finished={false}
                />
              )}
            </Tab>
            <Tab eventKey="longer-tab" title="Finished">
              {therapistSchedules != null && therapistSchedules.schedules && (
                <StreamsList
                  streams={therapistSchedules.schedules.filter(
                    (stream) =>
                      stream.streamStatus != "UPCOMING" &&
                      stream.streamStatus != "STARTED" &&
                      stream.bookingStatus != "Rescheduled"
                  )}
                  therapist={therapistSchedules.therapistDetails}
                  onStreamStart={(streamData) => whenStreamStart(streamData)}
                  finished={true}
                />
              )}
            </Tab>
            <Tab eventKey="Canceled" title="Cancelled">
              {therapistSchedules != null && therapistSchedules.schedules && (
                <StreamsList
                  streams={therapistSchedules.schedules.filter(
                    (stream) =>
                      stream.bookingStatus == "CANCELED" &&
                      stream.bookingStatus != "Rescheduled"
                  )}
                  therapist={therapistSchedules.therapistDetails}
                  onStreamStart={(streamData) => whenStreamStart(streamData)}
                  finished={false}
                />
              )}
            </Tab>
          </Tabs>
        </section>
      </main>
    </>
  );
}

export default StreamManager;
