import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Utils from "../../Utils/Utils";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert";
import { Toaster, toast } from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import { Alert } from "react-bootstrap";
import { Modal } from "antd";

function TherapistDashboard({ loggedInstructorDetails, navigateToProfile }) {
  const {
    therapistSchedules,
    approveScheduleByTherapist,
    getClientsScheduleData,
    scheduleLoading,
    programs,
    getAllScheduledStreams,
    acceptRequest,
    getRescheduleList,
    rescheduleSession,
    rescheduleRequest,
    DeclineRescheduleRequest,
  } = useContext(AppContext);
  // const [showOptions, setShowOptions] = useState({
  //   status: false,
  //   index: -1,
  // });

  const [loadingIndex, setLoadingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log("therapistSchedules", therapistSchedules);
  // const toggleAction = (index) => {
  //   setShowOptions({
  //     status: !showOptions.status,
  //     index: index,
  //   });
  // };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleApproval = async (data, id) => {
    console.log(id);
    if (!data) return;
    setLoadingIndex(id);
    const payload = {
      therapistId: data.therapistId,
      bookingId: data._id,
      status: "accepted",
    };

    await approveScheduleByTherapist(payload, {
      onSuccess: async (res) => {
        toast.success("Booking Accepted");
        await getClientsScheduleData();
        await getAllScheduledStreams();
        console.log(res);
      },
      onFailed: (err) => {
        console.error("ERROR ON ACCEPTING A BOOKING", err);
      },
    });
  };
  console.log(rescheduleRequest, "data");

  const handleDecline = async (data) => {
    const payload = {
      requestId: data._id,
      bookingId: data.bookingId,
    };

    await DeclineRescheduleRequest(payload, {
      onSuccess: async (res) => {
        toast.success(res.message);
        await getRescheduleList();
        window.location.reload();
      },
      onFailed: (err) => {
        console.error("Please Try Again Later", err);
      },
    });
  };

  const handleRescheduleApproval = async (data) => {
    const payload = {
      date: data.date,
      startTime: data.startTime,
      endTime: data.endTime,
      bookingId: data.bookingId,
      requestId: data._id,
    };

    await acceptRequest(payload, {
      onSuccess: async (res) => {
        toast.success("Session Rescheduled Successfully!");

        await getClientsScheduleData();
        await getRescheduleList();
        setTimeout(() => {
          setIsModalOpen(false);
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  const RescheduleAccept = (data) => {
    confirmAlert({
      title: "Are you sure?",
      message:
        "If you confirm this message, it will reschedule the session and also accept the session request.",
      buttons: [
        {
          className: "confirm-btn",
          label: "Yes",
          onClick: () => handleRescheduleApproval(data),
        },
        {
          className: "clear-button",
          label: "No",
        },
      ],
    });
  };

  return (
    <div>
      <Toaster position="top-center" />
      <section class="main ml-3">
        <h1>Welcome Therapist</h1>

        <div class="container mt-4">
          <div class="row">
            {/* <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="card inst_card therapist p-4">
                <h2>Sessions</h2>
                <p>
                  Assigned Sessions: <span>5</span>
                </p>
                <p>
                  Session Details: <span>Yoga master cla...</span>
                  <i>
                    <Ai.AiFillInfoCircle />
                    <div class="tooltip-therapist">
                      <p>list of sessions</p>
                    </div>
                  </i>
                </p>
                <p>
                  Users: <span>7</span>
                </p>
                <div className="dash_icon">
                  <img src={require("../../assets/teacher.png")} alt="" />
                </div>
              </div>
            </div> */}
            {/* <div class="col-lg-4 col-md-4 col-sm-6">
              <div class="card inst_card therapist p-4">
                <h2>Upcoming session</h2>
                <p>
                  Title: <span>Meditation</span>
                </p>
                <p>
                  User: <span>Joshua Mathew</span>
                </p>
                <p>
                  Date: <span>22/01/2023</span>
                </p>
                <p>
                  Duration <span>10:00AM - 11:00AM</span>
                </p>
                <div className="dash_icon">
                  <img src={require("../../assets/upcoming.png")} alt="" />
                </div>
              </div>
            </div> */}
            <div class="col-lg-4 col-md-4 col-sm-6">
              <div class="card inst_card therapist p-4">
                <h2>Profile</h2>
                {loggedInstructorDetails == null ? (
                  <div className="spinner-border" role="status"></div>
                ) : (
                  <>
                    <p>{loggedInstructorDetails?.name ?? "N/A"}</p>
                    <p>
                      <span>
                        {loggedInstructorDetails?.experience ?? "N/A"}
                      </span>
                    </p>
                    <p>
                      <span>
                        {loggedInstructorDetails?.desigination ?? "N/A"}
                      </span>
                    </p>
                    <p>
                      <span>
                        {loggedInstructorDetails?.phonenumber ?? "N/A"}
                      </span>
                    </p>
                    {/* <button
                          type="button"
                          className="button-thera"
                          onClick={() =>
                            navigateToProfile(loggedInstructorDetails._id)
                          }
                        >
                        <i className=""><Ai.AiOutlineEdit/></i>
                          Edit
                        </button> */}
                    <button
                      class="mdc-button mdc-button--raised mdc-button--leading button-thera"
                      type="button"
                      className="button-thera"
                      onClick={() =>
                        navigateToProfile(loggedInstructorDetails._id)
                      }
                    >
                      <i
                        class="material-icons mdc-button__icon"
                        aria-hidden="true"
                      >
                        <Ai.AiOutlineEdit />
                      </i>
                      <span class="mdc-button__label">Edit</span>
                    </button>
                    <div class="profile_pic_therapist">
                      <img
                        src={
                          loggedInstructorDetails.photo == null
                            ? require("../../assets/placeholder.webp")
                            : process.env.REACT_APP_IMAGE_URL +
                              loggedInstructorDetails.photo
                        }
                        alt=""
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div class="row" style={{ overflow: "visible" }}>
            {therapistSchedules != null &&
              therapistSchedules.schedules != null &&
              therapistSchedules.schedules
                .toReversed()
                .map((schedule, index) => {
                  const users = schedule.userDetails;
                  const scheduleDetails = schedule.scheduleDetails;
                  const rescheduleData = schedule.userDetails;
                  console.log(schedule, "schedule");
                  return (
                    schedule.bookingStatus !== "Rescheduled" &&
                    schedule.bookingStatus !== "CANCELED" &&
                    schedule.scheduleStatus != "requested" && (
                      <>
                        <div
                          className="col-lg-4 col-md-4 col-sm-4 mb-3"
                          key={index}
                        >
                          <div className="card inst_card p-2">
                            <h5>Session Request</h5>
                            <h4 className="mb-2 mt-2">
                              {schedule?.theraphyDetails?.therapyName ?? "N/A"}
                            </h4>
                            <p className="sched_date">
                              <Ai.AiTwotoneCalendar />{" "}
                              {schedule.date == "Invalid date" ? (
                                "Date Not Available"
                              ) : (
                                <>{Utils.formatDate(schedule?.date)}</>
                              )}
                            </p>
                            <p id="time_">
                              {schedule.scheduleDetails != null
                                ? schedule?.startTime
                                : "00"}
                              -
                              {schedule.scheduleDetails != null
                                ? schedule?.endTime
                                : "00"}
                            </p>
                            <div className="user_data">
                              {users.map((user, userIndex) => (
                                <ul className="user_list" key={userIndex}>
                                  <li>
                                    <p>{user.name ?? "N/A"}</p>
                                    <span>{user?.email ?? "N/A"}</span>
                                  </li>
                                </ul>
                              ))}
                            </div>

                            <hr />
                            {schedule != null &&
                            schedule.scheduleStatus === "pending" ? (
                              <div className="button_grp mt-2">
                                <button
                                  className="opt_ acc_"
                                  onClick={() =>
                                    handleApproval(schedule, index)
                                  }
                                  disabled={
                                    scheduleLoading && loadingIndex === index
                                  }
                                >
                                  {scheduleLoading && loadingIndex === index ? (
                                    <BeatLoader color="#fff" size={10} />
                                  ) : (
                                    <>
                                      <Bs.BsCheck2 /> <span>Accept</span>
                                    </>
                                  )}
                                </button>
                              </div>
                            ) : (
                              <p className="accepted_">
                                <Bs.BsCheck2All /> Accepted
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )
                  );
                })}
            {rescheduleRequest != null &&
              rescheduleRequest.map((data, index) => {
                const reScheduleDate = Utils.isValidDate(data?.date);

                if (data.responseStatus != "Declined") {
                  const therapyDetails = data.therapy;
                  const userData = data.userDetails;

                  return (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 mb-3"
                      key={index}
                    >
                      <div className="card inst_card p-2">
                        <h5>Reschedule Request</h5>
                        <h4 className="mb-2 mt-2">
                          {therapyDetails?.therapyName ?? "N/A"}
                        </h4>

                        <div className="user_data">
                          {userData?.name ?? "N/A"}
                        </div>
                        <p className="sched_date">
                          {Utils.formatDate(data?.existingDate)}
                        </p>

                        <p id="time_" className="mb-3">
                          {data?.existingStartTime ?? "N/A"}-
                          {data?.existingEndTime ?? "N/A"}
                        </p>

                        <hr />
                        <h6 className="mt-2">Reschedule Details</h6>
                        <p className="sched_date">
                          {" "}
                          {reScheduleDate === false ? data?.date : Utils.formatDate(data?.date)}
                        </p>

                        <p id="time_" className="mb-3">
                          {data?.startTime ?? "N/A"}-{data?.endTime ?? "N/A"}
                        </p>
                        <p id="time_" className="mb-3">
                          <b>Reason : </b>
                          {data?.reason ?? "N/A"}
                        </p>
                        <div className="d-flex">
                          <button
                            className="opt_ acc_"
                            onClick={() => RescheduleAccept(data)}
                          >
                            Accept
                          </button>
                          <button
                            className="decline"
                            onClick={() => handleDecline(data)}
                          >
                            Decline
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }

                return null;
              })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default TherapistDashboard;
