/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Button } from "antd";
import Avatar from "react-avatar-edit";
import toast, { Toaster } from "react-hot-toast";
import { Dialog } from "primereact/dialog";
import * as Ai from "react-icons/ai";
import "antd/dist/reset.css";
import Profile from "../../assets/placeholder.png";
import { AppContext } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./Home.css";
import Utils from "../../Utils/Utils";
function EditTherapist() {
  const navigate = useNavigate();
  const params = useParams();
  const [dialogues, setDialogues] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [editInstructorDetails, setEditInstructorDetails] = useState({});
  const [formErrors, setFormErrors] = useState("");

  const {
    getTherapist,
    loggedInstructorDetails,
    editInstructor,
    getCurrentLoggedTherapistDetails,
  } = useContext(AppContext);

  // useEffect(() => {
  //   if (loggedInstructorDetails.photo != null) {
  //     setFile(loggedInstructorDetails.photo);
  //   }
  // }, [loggedInstructorDetails]);

  const onCrop = (view) => {
    setFile(Utils.createBlobFile(view));
  };
  // ...

  const onClose = () => {
    setDialogues(false);
    setFile(null);
  };

  // ...

  const closeCropModal = () => {
    setDialogues(false);
  };

  useEffect(() => {
    if (loggedInstructorDetails != null) {
      setEditInstructorDetails(loggedInstructorDetails);
    }
  }, [loggedInstructorDetails]);

  useEffect(() => {
    validator(editInstructorDetails);
  }, [editInstructorDetails]);

  const onChangeEditingField = ({ name, value }) => {
    if (name.length == 0) return;
    let EditInstDetails = editInstructorDetails;
    EditInstDetails[name] = value;
    setEditInstructorDetails({ ...EditInstDetails });
  };

  const validator = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "This field cannot be blank";
    }
    if (!values.lastName) {
      errors.lastName = "This field cannot be blank";
    }
    if (!values.email) {
      errors.email = "This field cannot be blank";
    }
    if (!values.phonenumber) {
      errors.phonenumber = "This field cannot be blank";
    }

    if (!values.desigination) {
      errors.desigination = "This field cannot be blank";
    }
    if (!values.experience) {
      errors.experience = "This field cannot be blank";
    }
    if (!values.bio && values.bio !== null) {
      errors.bio = "This field cannot be blank";
    }
    return errors;
  };
  const phoneNumberRegex = /^[0-9+\s-]+$/;

  const updateInstructorDetails = async (e) => {
    e.preventDefault();
    const errors = validator(editInstructorDetails);
    setFormErrors(errors);

    if (!phoneNumberRegex.test(editInstructorDetails.phonenumber)) {
      setFormErrors({
        ...errors,
        phonenumber: "Please enter a valid phonenumber",
      });
      return;
    }

    if (Object.keys(errors).length == 0) {
      setFormErrors({});
      delete editInstructorDetails._id;

      const bodyFormData = Utils.createFormDataFromFormValues({
        ...editInstructorDetails,
        file: file,
      });
      console.log(bodyFormData.getAll("bio"), "editInstructorDetails");

      const id = params.id;

      editInstructor(bodyFormData, id, {
        onSuccess: async (data) => {
          await getCurrentLoggedTherapistDetails();
          toast.success("User details updated successfully!");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        },

        onFailed: (err) => {
          console.log("error", err);
          toast.error("Error updating user details!");
        },
      });
    }
  };
  const navigateBack = () => {
    window.location.href = "/";
  };

  return (
    <div>
      <Toaster position="top-center" />
      <Header />

      <Container fluid="md">
        <main>
          <section className="profile-div">
            {!dialogues && (
              <button className="back_btn" onClick={() => navigateBack()}>
                <Ai.AiOutlineLeft />
              </button>
            )}

            {!dialogues && <h2 className="text-center">Edit Therapist</h2>}
            <form onSubmit={(e) => updateInstructorDetails(e)}>
              {dialogues && (
                <div className="">
                  <Dialog
                    visible={dialogues}
                    header={() => (
                      <div className="">
                        <p className="text-center">
                          Update New Profile Picture
                        </p>
                      </div>
                    )}
                    onHide={() => setDialogues(false)}
                  >
                    <div className="confirmation-content flex flex-column align-items-center">
                      <div className="">
                        <div>
                          <Avatar
                            width={400}
                            height={300}
                            onClose={closeCropModal}
                            onCrop={onCrop}
                          />
                          <div className="text-center mx-auto">
                            <Button
                              label="Save"
                              onClick={closeCropModal}
                              className="mt-4 mb-3"
                            >
                              Save
                            </Button>
                            <Button
                              label="Save"
                              onClick={onClose}
                              className="mt-4 mb-3 m-3"
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog>
                </div>
              )}
              {!dialogues && (
                <>
                  <Row>
                    <Col>
                      <div className="user-profile">
                        <div className="flex flex-column justify-content-center align-items-center">
                          <img
                            src={
                              file != null
                                ? URL.createObjectURL(file)
                                : editInstructorDetails.photo != null
                                ? process.env.REACT_APP_IMAGE_URL +
                                  editInstructorDetails.photo
                                : Profile
                            }
                            alt="Click here to upload Image"
                            className="new-im"
                            style={{ maxWidth: "100%" }}
                            onClick={() => {
                              setDialogues(true);
                            }}
                          />

                          <div className="confirmation-content flex flex-column align-items-center">
                            <div className="flex flex-column align-items-center mt-5 w-12">
                              <div className="flex flex-column justify-content-around w-12 mt-4">
                                <Button
                                  label="Save"
                                  onClick={closeCropModal}
                                  className="mt-4 saved"
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="col-pro">
                    <Col>
                      <div class="form-group">
                        <label for="name" className="text-nowrap">
                          First Name
                        </label>{" "}
                        <span style={{ color: "red" }}> *</span>
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          onChange={(e) => onChangeEditingField(e.target)}
                          value={editInstructorDetails.firstName}
                        />
                        {formErrors.firstName &&
                          !editInstructorDetails.firstName && (
                            <p>{formErrors.firstName}</p>
                          )}
                      </div>
                    </Col>
                    <Col>
                      <div class="form-group">
                        <label for="name" className="text-nowrap">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          onChange={(e) => onChangeEditingField(e.target)}
                          value={editInstructorDetails.lastName}
                        />
                        {formErrors.lastName &&
                          !editInstructorDetails.lastName && (
                            <p>{formErrors.lastName}</p>
                          )}
                      </div>
                    </Col>
                    <Col>
                      <div class="form-group">
                        <label for="email">Email</label>{" "}
                        <span style={{ color: "red" }}> *</span>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={editInstructorDetails.email}
                          onChange={(e) => onChangeEditingField(e.target)}
                        />
                        {formErrors.email && !editInstructorDetails.email && (
                          <p>{formErrors.email}</p>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label htmlFor="phone">Phone</label>{" "}
                        <span style={{ color: "red" }}> *</span>
                        <input
                          type="text"
                          className="form-control"
                          name="phonenumber"
                          value={editInstructorDetails.phonenumber}
                          onChange={(e) => onChangeEditingField(e.target)}
                        />
                        {formErrors.phonenumber && (
                          <p className="text-danger">
                            {formErrors.phonenumber}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <Row className="col-pro">
                    {/* <Col>
                      <label>Profession</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        name="profession"
                        value={editInstructorDetails.profession}
                        onChange={(e) => onChangeEditingField(e.target)}
                      />
                      {formErrors.profession &&
                        !editInstructorDetails.profession && (
                          <p>{formErrors.profession}</p>
                        )}
                    </Col> */}
                    <Col>
                      <label for="">Experience</label>
                      <input
                        type="text"
                        name="experience"
                        className="form-control"
                        value={editInstructorDetails.experience}
                        onChange={(e) => onChangeEditingField(e.target)}
                      />
                    </Col>
                  </Row>
                  <Row className="col-pro">
                    <Col>
                      <label for="">Designation</label>
                      <input
                        type="text"
                        name="desigination"
                        className="form-control"
                        value={editInstructorDetails.desigination}
                        onChange={(e) => onChangeEditingField(e.target)}
                      />
                    </Col>
                    <Col>
                      <label for="">Bio</label>
                      <input
                        type="text"
                        name="bio"
                        className="form-control"
                        value={editInstructorDetails.bio}
                        onChange={(e) => onChangeEditingField(e.target)}
                      />
                    </Col>
                  </Row>

                  <Row className="buttons col-pro">
                    <Col className="text-center">
                      <Col className="mx-auto">
                        <button type="submit" className="edit_btn">
                          Update
                        </button>
                      </Col>
                    </Col>
                  </Row>
                </>
              )}
            </form>
          </section>
        </main>
      </Container>
    </div>
  );
}

export default EditTherapist;
