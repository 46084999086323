import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Utils from "../../Utils/Utils";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { FaBell } from "react-icons/fa";
import dayjs from "dayjs";
import { Modal, Space, TimePicker } from "antd";
import { X } from "react-feather";
import { BeatLoader } from "react-spinners";

function StreamsList({
  onStreamStart,
  showStart,
  finished,
  streams,
  onRestart,
  therapist,
}) {
  const {
    programs,
    registerdUsers,
    rescheduleRequest,
    cancelSession,
    rescheduleSession,
    therapistSchedules,
    therapists,
    scheduleLoading,
    getClientsScheduleData,
  } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rescheduleData, setRescheduleData] = useState(null);
  const [reModal, setReModal] = useState(false);
  const [therapistDetails, setTherapistDetails] = useState(null);
  const [restart, setRestart] = useState(false);
  const [editedValues, setEditedValues] = useState({
    startTime: "",
    endTime: "",
    description: "",
    date: "",
    therapistId: "",
    dateSchedule: false,
  });
  const [currentTime, setCurrentTime] = useState("");
  useEffect(() => {});

  useEffect(() => {
    const updateCurrentTime = () => {
      const currentDate = new Date();
      const formattedTime = currentDate.toLocaleTimeString();
      setCurrentTime(formattedTime);
    };

    updateCurrentTime();

    const intervalId = setInterval(updateCurrentTime, 60000);
    return () => clearInterval(intervalId);
  }, []);
  console.log(streams, "stream");
  const handleCancelSession = async (stream) => {
    const payload = {
      therapistId: stream.therapistId,
      bookingId: stream._id,
      therapyId: stream.scheduleDetails.therapyId,
      cancelledBy: "Therapist",
      cancelledDate: new Date(),
    };

    await cancelSession(payload, {
      onSuccess: async (res) => {
        toast.success("Session Cancelled Successfully!");
        window.location.reload();
      },
      onFailed: (err) => {
        toast.error(err.data);
      },
    });
  };

  const handleShow = (stream) => {
    setReModal(true);
    setRescheduleData(stream);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setReModal(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setReModal(false);
  };
  const reschedule = (stream) => {
    const streamId = stream._id;
    setIsModalOpen(true);
    const data = streams.find((x) => x._id == streamId);
    setEditedValues(data);
  };

  useEffect(() => {
    setEditedValues((prevValues) => ({
      startTime: prevValues?.startTime ?? "N/A",
      endTime: prevValues?.endTime ?? "N/A",
      therapyId: prevValues?._id ?? "N/A",
      therapistId: prevValues?._id ?? "N/A",
      date: prevValues?.bookingDate ?? "N/A",
      dateSchedule: prevValues?.bookingData ?? false,
    }));
  }, []);

  const onChangeTimePicker = (time, timeString, type) => {
    switch (type) {
      case "startTime":
        setEditedValues((prev) => ({ ...prev, startTime: timeString }));
        return;
      case "endTime":
        setEditedValues((prev) => ({ ...prev, endTime: timeString }));
        return;
      default:
        return;
    }
  };

  const onDateInputChange = (value) => {
    const dateConvert = new Date(value);
    if (!editedValues.date) return;
    setEditedValues((prevValues) => ({
      ...prevValues,
      date: dateConvert,
    }));
  };

  const onChangeValue = (e) => {
    const { name, value } = e.target;

    if (name === "therapistId" && value !== "") {
      setEditedValues((prev) => ({
        ...prev,
        therapistId: value,
      }));
    } else {
      setEditedValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleReschedule = async (e) => {
    e.preventDefault();
    if (
      editedValues.startTime.length === 0 ||
      editedValues.endTime.length === 0
    ) {
      toast.error("Time duration is required!");
      return;
    }
    if (editedValues.startTime > editedValues.endTime) {
      toast.error("Please fill valid time!");
      return;
    }

    const payload = {
      bookingId: editedValues.bookingParentId,
      slotId: editedValues._id,
      therapistId: editedValues.therapistId,
      date: editedValues.date,
      startTime: editedValues.startTime,
      endTime: editedValues.endTime,
      dateSchedule: editedValues.dateSchedule,
    };

    await rescheduleSession(payload, {
      onSuccess: async (res) => {
        setIsModalOpen(false);
        toast.success("Session Rescheduled Successfully!");
        await getClientsScheduleData();
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  // console.log("therapistSchedules", rescheduleRequest);

  // const handleRestart = (stream) => {
  //   console.log("called");
  //   // console.log(stream, "stream");
  //   // const currentDateTime = moment().format("DD-MM-YYYY hh:mm A");
  //   // console.log("currentDateTime", currentDateTime);
  //   // const streamEndTime =
  //   //   Utils.formatDate(new Date(stream?.date)) + " " + stream?.endTime;
  //   // console.log("liveDateAndTime", streamEndTime);
  //   // const date = Utils.formatDate(new Date());
  //   // const streamDate = Utils.formatDate(stream?.date);

  //   // if (date != streamDate) return false;
  //   // if (currentTime >= stream?.endTime) return false;
  //   // return true;
  //   const currentDate = new Date().toLocaleDateString();
  //   const date = new Date(stream.date);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1);
  //   const day = String(date.getDate());
  //   const formattedDate = `${month}/${day}/${year}`;

  //   console.log(formattedDate);

  //   if (formattedDate == currentDate) {
  //     console.log("matched");
  //   }
  // };
  const handleRestart = (stream) => {
    const currentDate = new Date().toLocaleDateString();
    const date = new Date(stream.date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1);
    const day = String(date.getDate());
    const formattedDate = `${month}/${day}/${year}`;

    console.log(formattedDate);

    return currentDate == formattedDate;
  };

  return (
    <>
      <Container>
        <div>
          <Modal
            open={reModal}
            onOk={() => setReModal(false)}
            onCancel={() => setReModal(false)}
          >
            <>
              <h6 className="reschedule_info_txt">
                Client has requested a reschedule. Currently awaiting approval.
                Please check the dashboard for more details and respond to the
                request
              </h6>
            </>
          </Modal>
          <Modal
            title="Reschedule Session"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <form onSubmit={(e) => handleReschedule(e)}>
              {" "}
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={moment(editedValues.date).format("YYYY-MM-DD")}
                      onChange={(e) => onDateInputChange(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="col">
                      <div class="form-group">
                        <label for="">Therapy</label>
                        <select
                          name="therapyId"
                          id=""
                          className="form-select"
                          value={editedValues.therapyId}
                          onChange={(e) => onChangeValue(e)}
                        >
                          <option value="0">Select Therapy</option>
                          {programs.length > 0 &&
                            programs.map((therapy, index) => (
                              <option key={index} value={therapy._id}>
                                {therapy.therapyName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div> */}
              </div>
              <div className="row">
                <div class="form-group col">
                  <label for="">Start Time</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      borderColor: "transparent",
                    }}
                  >
                    <TimePicker
                      use12Hours
                      format="h:mm A"
                      value={
                        dayjs(editedValues.startTime, "h:mm A").isValid()
                          ? dayjs(editedValues.startTime, "h:mm A")
                          : null
                      }
                      onChange={(time, timeString) =>
                        onChangeTimePicker(time, timeString, "startTime")
                      }
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                        outline: "none",
                        boxShadow: "0 0 0 transparent",
                      }}
                      className="form-control"
                      placeholder="Select Start Time"
                    />
                  </Space>
                </div>

                <div class="form-group col">
                  <label for="">End Time</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      borderColor: "transparent",
                    }}
                  >
                    <TimePicker
                      use12Hours
                      format="h:mm A"
                      value={
                        dayjs(editedValues.endTime, "h:mm A").isValid()
                          ? dayjs(editedValues.endTime, "h:mm A")
                          : null
                      }
                      onChange={(time, timeString) =>
                        onChangeTimePicker(time, timeString, "endTime")
                      }
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                        outline: "none",
                        boxShadow: "0 0 0 transparent",
                      }}
                      className="form-control"
                    />
                  </Space>
                </div>
              </div>
              <div className="mx-auto text-center">
                <button
                  className="res-schedule-sub"
                  type="submit"
                  disabled={
                    scheduleLoading.action == "reschedule" &&
                    scheduleLoading.loading === true
                      ? true
                      : false
                  }
                >
                  {scheduleLoading.action == "reschedule" &&
                  scheduleLoading.loading === true ? (
                    <BeatLoader color="#fff" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </Modal>
        </div>
        {streams &&
          streams.length > 0 &&
          streams.map((stream) => {
            const validDate = Utils.isValidDate(stream.date);
            const schedule = stream?.scheduleDetails;
            const userId = stream.userIds;
            const users = stream?.users;
            const bookingData = stream.bookingStatus;
            const cancelledDetails = stream?.cancelInformation;

            const therapy = programs.find((x) => x._id == schedule.therapyId);
            const findUser =
              registerdUsers != null && registerdUsers.length !== 0
                ? userId != null && userId.length !== 0
                  ? registerdUsers.filter((y) => userId.includes(y._id))
                  : users != null && users.length !== 0
                  ? registerdUsers.filter((y) => users.includes(y._id))
                  : []
                : null;
            const canRestart = handleRestart(stream);
            return (
              <div class="card schedule_card schedule_details_card mt-3">
                <Row>
                  <Col xl={3}>
                    {/* <p style={{ fontWeight: "500" }}>
                      Therapist Name : <span>{therapist?.name ?? "N/A"}</span>
                    </p> */}
                    <p style={{ fontWeight: "500" }}>
                      Schedule Type:{" "}
                      <span>
                        {stream.dateSchedule == true
                          ? "Date Schedule"
                          : "Weekly Schedule"}
                        {stream.scheduleStatus == "requested" &&
                          bookingData != "CANCELED" && (
                            <i
                              className="red ml-3"
                              onClick={() => handleShow(stream)}
                            >
                              <FaBell />
                            </i>
                          )}
                      </span>
                    </p>
                    <div className="det">
                      <div className="icon_div">
                        <p style={{ fontWeight: "500" }}>
                          Client Name :{" "}
                          <span>
                            {findUser && findUser.length !== 0
                              ? findUser.map((name, index) => {
                                  return index === findUser.length - 1
                                    ? name.name
                                    : name.name + ", ";
                                })
                              : "N/A"}
                          </span>
                        </p>
                      </div>
                      <div className="icon_div">
                        <p style={{ fontWeight: "500" }}>
                          Time :{" "}
                          <span>
                            {stream?.startTime ?? "N/A"} to{" "}
                            {stream?.endTime ?? "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <p style={{ fontWeight: "500" }}>
                      Therapy Name :{" "}
                      <span>{therapy?.therapyName ?? "N/A"}</span>{" "}
                    </p>
                    <p style={{ fontWeight: "500" }}>
                      Date :{" "}
                      <span>
                        {stream.date && Utils.formatDate(stream.date)
                          ? Utils.formatDate(stream.date)
                          : stream.date}
                      </span>{" "}
                    </p>
                    <p className="des_">
                      {therapy?.therapyDescription ?? "N/A"}
                    </p>
                    {/* <div className="icon_div">
                    <label for="">Time</label>
                    <space /> <input type="time" /> to <input type="time" />
                    </div>
                     */}
                  </Col>

                  <Col xl={3}>
                    {stream.scheduleStatus == "pending" &&
                      stream.bookingStatus != "CANCELED" && (
                        <div class="start_stream_btn mx-auto not_acc_">
                          Not Accepted
                        </div>
                      )}
                    {stream.streamStatus == "STARTED" && (
                      <div className="text-center">
                        <button
                          class="Restart_stream_btn mx-auto"
                          onClick={() =>
                            onStreamStart({ ...stream, therapist })
                          }
                          // disabled={!selectedTherpy}
                        >
                          Rejoin
                        </button>
                      </div>
                    )}
                    {canRestart && stream.streamStatus != "UPCOMING" && (
                      <div className="text-center">
                        <button
                          className="start_stream_btn mx-auto mt-2 mb-2"
                          onClick={() =>
                            onStreamStart({ ...stream, therapist })
                          }
                        >
                          Restart
                        </button>
                      </div>
                    )}
                    {/* {stream.bookingStatus != "CANCELED" && (
                      <div className="row mt-3">
                        {stream.scheduleStatus == "pending" ? (
                          <div class="start_stream_btn mx-auto not_acc_">
                            Not Accepted
                          </div>
                        ) : (
                          <button
                            class="start_stream_btn mx-auto"
                            onClick={() =>
                              onStreamStart({ ...stream, therapist })
                            }
                            // disabled={!selectedTherpy}
                          >
                            start
                          </button>
                        )}
                      </div>
                    )} */}
                    {stream.streamStatus == "UPCOMING" &&
                      stream.paymentStatus != "Not Received" &&
                      stream.bookingStatus != "CANCELED" &&
                      stream.scheduleStatus != "pending" && (
                        <div className="text-center">
                          <button
                            class="start_stream_btn mx-auto"
                            onClick={() =>
                              onStreamStart({ ...stream, therapist })
                            }
                            // disabled={!selectedTherpy}
                          >
                            Start
                          </button>
                        </div>
                      )}
                    {stream.streamStatus == "UPCOMING" &&
                      stream.bookingStatus != "CANCELED" && (
                        <div className="mt-3 approval mx-auto text-center">
                          <button
                            class="reschedule-st mx-auto "
                            onClick={() => reschedule(stream)}
                          >
                            Re-schedule
                          </button>
                        </div>
                      )}
                    {stream.streamStatus == "UPCOMING" &&
                      stream.bookingStatus != "CANCELED" && (
                        <div className="mt-3 approval mx-auto text-center">
                          <button
                            class="cancel-st mx-auto"
                            onClick={() => handleCancelSession(stream)}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    {stream?.bookingStatus === "CANCELED" && (
                      <div className="mt-3 approval mx-auto text-center">
                        <p className="canceled-session">
                          This session is Cancelled by{" "}
                          {cancelledDetails?.cancelledBy == "Therapist"
                            ? "you"
                            : cancelledDetails?.cancelledBy}{" "}
                          on{" "}
                          {Utils.formatDate(cancelledDetails?.cancelledDate) ??
                            "Date Not Availble"}
                          <br />
                        </p>
                      </div>
                    )}
                  </Col>
                  <Col xl={3}>
                    {stream.paymentStatus == "Not Received" && (
                      <span class="badge new pull-right ml-1">
                        Payment Pending
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            );
          })}
      </Container>
    </>
  );
}

export default StreamsList;
