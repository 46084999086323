import React, { useEffect, useState } from "react";
import { APIEndpoints } from "../constants/APIEndpoints";
import BaseClient from "../Helper/BaseClient";

const useUsers = () => {
  const [registerdUsers, setRegisteredUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);

  const getRegisteredUsers = async () => {
    try {
      setUsersLoading(true);
      await BaseClient.get(
        APIEndpoints.listAllRegisteredUsers,
        {
          onSuccess: (res) => {
            if (!Array.isArray(res.data)) return;
            setRegisteredUsers(res.data);
          },
        },
        {
          onFailed: (err) => {
            return console.log("Error", err);
          },
        }
      );
    } finally {
      setUsersLoading(false);
    }
  };
  const deleteUser = async (id, { onSuccess, onFailed }) => {
    try {
      setUsersLoading(true);

      await BaseClient.delete(APIEndpoints.deleteUser + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setUsersLoading(false);
    }
  };
  const registerUser = async (payload, { onSuccess, onFailed }) => {
    try {
      setUsersLoading({ action: "Add", loading: true });
      await BaseClient.post(APIEndpoints.registerUser, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setUsersLoading({ action: "", loading: false });
    }
  };

  const updateUser = async (payload, { onSuccess, onFailed }) => {
    try {
      setUsersLoading(true);
      await BaseClient.post(APIEndpoints.editUser, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setUsersLoading(false);
    }
  };
  const forgotPassword = async (email, { onSuccess, onFailed }) => {
    try {
      setUsersLoading(true);
      await BaseClient.post(
        APIEndpoints.forgotPassword,
        { email },
        {
          onSuccess: onSuccess,
          onFailed: onFailed,
        }
      );
    } finally {
      setUsersLoading(false);
    }
  };
  // for OTP
  const otpVerification = async (payload, { onSuccess, onFailed }) => {
    try {
      setUsersLoading(true);
      await BaseClient.post(APIEndpoints.otpVerification, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setUsersLoading(false);
    }
  };
  // for resetpassword
  const resetPassword = async (payload, { onSuccess, onFailed }) => {
    try {
      setUsersLoading(true);
      await BaseClient.post(APIEndpoints.resetPassword, payload, {
        onSuccess: onSuccess,
        onFailed,
        onFailed,
      });
    } finally {
      setUsersLoading(false);
    }
  };
  const resendOTP = async (payload, { onSuccess, onFailed }) => {
    try {
      setUsersLoading(true);
      await BaseClient.post(APIEndpoints.resentOtp, payload, {
        onSuccess: onSuccess,
        onFailed,
        onFailed,
      });
    } finally {
      setUsersLoading(false);
    }
  };
  return {
    resetPassword,
    forgotPassword,
    otpVerification,
    getRegisteredUsers,
    registerUser,
    usersLoading,
    updateUser,
    deleteUser,
    resendOTP,
    registerdUsers,
  };
};

export default useUsers;
