import React, { useState } from "react";
import { useContext } from "react";
import { APIEndpoints } from "../constants/APIEndpoints";
import { AppContext } from "../context/AppContext";
import BaseClient from "../Helper/BaseClient";

export default function useStream() {
  const [streamLoading, setStreamLoading] = useState(false);
  const [allStreams, setAllStreams] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [progress, setProgress] = useState("0");
  const [noteLoading, setNoteLoading] = useState(false);

  const changeBroadcastStatus = async ({ therapistId, bookingId, status }) => {
    await BaseClient.put(
      APIEndpoints.changeScheduleStreamStatus,
      { bookingId, status, therapistId },
      {
        onSuccess: (data) => {
          console.log("Broadcast Status Changed to " + status);
          console.log("<-- Broadcast Status Details -->" + data);
        },
        onFailed: (error) => {
          console.error("Broadcast Status Change Error " + status);
          console.error("<-- Broadcast Status Error Details -->" + error);
        },
      }
    );
  };

  const getAllScheduledStreams = async () => {
    try {
      setStreamLoading(true);
      await BaseClient.get(APIEndpoints.getScheduledTherapyByTherapist, {
        onSuccess: (res) => {
         
          if (res.result != null) {
            setAllStreams(res.result);
          }
        },
        onFailed: (err) => {
          console.log("error", err);
        },
      });
    } finally {
      setStreamLoading(false);
    }
  };

  const fileSharingToUser = async (payload, { onSuccess, onFailed }) => {
    try {
      setFileLoading(true);
      setProgress("50");
      await BaseClient.post(APIEndpoints.fileSharing, payload, {
        headers: { "Content-Type": "multipart/form-data" },
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setFileLoading(false);
    }
  };
  const sendNotes = async (payload, { onSuccess, onFailed }) => {
    try {
      setNoteLoading(true);
      await BaseClient.post(APIEndpoints.sendNotesToClient, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setNoteLoading(false);
    }
  };

  const updatePaymentStatus = async (payload, { onSuccess, onFailed }) => {
    try {
      setStreamLoading(true);
      await BaseClient.post(APIEndpoints.paymentStatusUpdate, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setStreamLoading(false);
    }
  };
  return {
    changeBroadcastStatus,
    getAllScheduledStreams,
    updatePaymentStatus,
    allStreams,
    fileSharingToUser,
    fileLoading,
    progress,
    setProgress,
    sendNotes,
  };
}
