import { useState } from "react";
import { APIEndpoints } from "../constants/APIEndpoints";
import BaseClient from "../Helper/BaseClient";
import useCookies from "./useCookies";
const useSchedule = () => {
  const [scheduleLoading, setScheduleLoading] = useState({
    action: "",
    loading: false,
  });

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [weeklyScheduleList, setWeeklyScheduleList] = useState({});
  const [scheduledSlot, setScheduledSlot] = useState([]);
  const [dateScheduleList, setDateScheduleList] = useState([]);
  const [pendingDateScheduleList, setPendingDateScheduleList] = useState([]);
  const [allSchedules, setAllSchedules] = useState(null);

  const weeklySchedule = async (payload, { onSuccess, onFailed }) => {
    try {
      setScheduleLoading({ action: "Add", loading: true });
      await BaseClient.post(APIEndpoints.weeklySchedule, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setScheduleLoading({ action: "", loading: false });
    }
  };

  const getWeeklyScheduleList = async () => {
    try {
      setScheduleLoading({ action: "GET", loading: true });
      await BaseClient.get(APIEndpoints.getWeeklyScheduleList, {
        onSuccess: (res) => {
          if (res?.result != null) {
            setWeeklyScheduleList(res?.result);
          }
        },
        onFailed: (error) => {
          console.log("Error", error);
          setWeeklyScheduleList({});
        },
      });
    } finally {
      setScheduleLoading({ action: "", loading: false });
    }
  };

  const getListOfScheduledSlots = async () => {
    try {
      setScheduleLoading({ action: "Add", loading: true });
      await BaseClient.get(APIEndpoints.getListOfScheduledSlots, {
        onSuccess: (res) => {
          console.log(res);
          if (res?.response != null && Array.isArray(res?.response)) {
            setScheduledSlot(res?.response);
          }
        },
        onFailed: (error) => {
          console.log("Error for shceduled slot", error);
        },
      });
    } finally {
      setScheduleLoading({ action: "", loading: false });
    }
  };

  const rescheduleSession = async (payload, { onSuccess, onFailed }) => {
    try {
      setScheduleLoading({
        action: "reschedule",
        loading: true,
      });
      await BaseClient.post(APIEndpoints.rescheduleSession, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setScheduleLoading({ action: "", loading: false });
    }
  };
  const createSessionFromAdmin = async (payload, { onSuccess, onFailed }) => {
    try {
      setScheduleLoading({
        action: "createAdmin",
        loading: true,
      });
      await BaseClient.post(APIEndpoints.createNewSession, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setScheduleLoading({ action: "", loading: false });
    }
  };
  const getAllScheduleListInAdmin = async () => {
    try {
      setScheduleLoading({ action: "Add", loading: true });
      await BaseClient.get(APIEndpoints.consolidatedSchedules, {
        onSuccess: (res) => {
          console.log(res, "res in admin");
          if (res?.response != null && Array.isArray(res?.response)) {
            setAllSchedules(res?.response);
          }
        },
        onFailed: (error) => {
          console.log("Error In shcedule Listing", error);
        },
      });
    } finally {
      setScheduleLoading({ action: "", loading: false });
    }
  };

  const approveScheduleByTherapist = async (
    payload,
    { onSuccess, onFailed }
  ) => {
    try {
      setScheduleLoading(true);

      await BaseClient.post(APIEndpoints.updateScheduleByTherapist, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setScheduleLoading(false);
    }
  };

  const approveScheduledSlot = async (
    id,
    scheduledSlotId,
    day,
    price,
    { onSuccess, onFailed }
  ) => {
    try {
      await BaseClient.put(
        APIEndpoints.approveShceduledSlot + id,
        { scheduleId: scheduledSlotId, day, price },
        {
          onSuccess: onSuccess,
          onFailed: onFailed,
        }
      );
    } finally {
      getListOfScheduledSlots();
    }
  };

  const scheduleByDate = async (payload, { onSuccess, onFailed }) => {
    try {
      setScheduleLoading({ action: "dateSchedule", loading: true });

      await BaseClient.post(APIEndpoints.dateSchedule, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setScheduleLoading({ action: "", loading: false });
    }
  };

  const getDateScheduleData = async () => {
    try {
      setScheduleLoading({ action: "GET", loading: true });
      await BaseClient.get(APIEndpoints.getDateSchedules, {
        onSuccess: (res) => {
          if (Object.keys(res.result).length === 0) return;
          // if (!Array.isArray(res.result.schedules)) return;

          setDateScheduleList(res.result);
        },
        onFailed: (err) => {
          console.log(err.message);
        },
      });
    } finally {
      setScheduleLoading({ action: "", loading: false });
    }
  };

  const getPendingDateSchedule = async () => {
    try {
      setScheduleLoading({ action: "get", loading: true });
      await BaseClient.get(APIEndpoints.pendingDateSchedule, {
        onSuccess: (res) => {
          if (!Array.isArray(res.response)) return;
          setPendingDateScheduleList(res.response);
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } finally {
      setScheduleLoading({ action: "", loading: false });
    }
  };

  const approveSingleDateSchedule = async (
    id,
    payload,
    { onSuccess, onFailed }
  ) => {
    try {
      setScheduleLoading({ action: "approve", loading: true });
      await BaseClient.put(
        APIEndpoints.approveDateSchedule + `/${id}`,
        payload,
        {
          onSuccess: onSuccess,
          onFailed: onFailed,
        }
      );
    } finally {
      setScheduleLoading({ action: "", loading: false });
    }
  };
  // delete daywise schedule

  const deleteSchedule = async (id, { onSuccess, onFailed }) => {
    try {
      setScheduleLoading(true);

      await BaseClient.delete(APIEndpoints.deleteSchedule + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setScheduleLoading(false);
    }
  };

  // delete weeklyschedule
  const deleteWeeklySchedule = async (payload, { onSuccess, onFailed }) => {
    try {
      setDeleteLoading(true);
      await BaseClient.post(APIEndpoints.deletescheduleOfWeek, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setDeleteLoading(false);
    }
  };
  const cancelSession = async (payload, { onSuccess, onFailed }) => {
    try {
      setScheduleLoading(true);

      await BaseClient.post(APIEndpoints.cancelSession, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setScheduleLoading(false);
    }
  };
  const cancelRefund = async (payload, { onSuccess, onFailed }) => {
    try {
      setScheduleLoading(true);
      await BaseClient.post(
        APIEndpoints.updateCancellationAndRefundStatus,
        payload,
        {
          onSuccess: onSuccess,
          onFailed: onFailed,
        }
      );
    } finally {
      setScheduleLoading(false);
    }
  };
  return {
    weeklySchedule,
    deleteSchedule,
    scheduleLoading,
    cancelSession,
    deleteWeeklySchedule,
    getWeeklyScheduleList,
    weeklyScheduleList,
    getListOfScheduledSlots,
    cancelRefund,
    scheduledSlot,
    approveScheduledSlot,
    scheduleByDate,
    getDateScheduleData,
    dateScheduleList,
    getPendingDateSchedule,
    pendingDateScheduleList,
    approveSingleDateSchedule,
    approveScheduleByTherapist,
    getAllScheduleListInAdmin,
    allSchedules,
    rescheduleSession,
    createSessionFromAdmin,
  };
};
export default useSchedule;
