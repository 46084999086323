import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { DatePicker, TimePicker, Space } from "antd";
import toast, { Toaster } from "react-hot-toast";
import dayjs from "dayjs";
import moment from "moment";
import * as Bs from "react-icons/bs";
import { AppContext } from "../../../context/AppContext";

function DateScheduleByAdmin({ selectedDate, formattedDate }) {
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [therapistId, setTherapistId] = useState("");
  const [dayError, setDayError] = useState(false);

  const initialValues = {
    startTime: null,
    endTime: null,
    startTimeValue: null,
    endTimeValue: null,
    therapyId: null,
    description: null,
  };

  const [scheduleSlots, setScheduleSlots] = useState([initialValues]);

  const {
    programs,
    therapists,
    scheduleLoading,
    scheduleByDate,
    getDateScheduleData,
  } = useContext(AppContext);

  const addNewSchedule = () => {
    setScheduleSlots([...scheduleSlots, { ...initialValues }]);
  };

  const deleteSchedule = (deleteIndex) => {
    setScheduleSlots(scheduleSlots.filter((_, index) => index !== deleteIndex));
  };

  const onChangeTimePicker = (time, timeString, index, type) => {
    switch (type) {
      case "startTime":
        console.log("timeString", timeString);
        scheduleSlots[index].startTime = timeString;
        scheduleSlots[index].startTimeValue = time;
        setScheduleSlots([...scheduleSlots]);
        return;
      case "endTime":
        scheduleSlots[index].endTime = timeString;
        scheduleSlots[index].endTimeValue = time;
        setScheduleSlots([...scheduleSlots]);
        return;
      default:
        return;
    }
  };

  const onChangeDescription = (index, value) => {
    const newScheduleSlots = [...scheduleSlots];
    newScheduleSlots[index] = {
      ...newScheduleSlots[index],
      description: value,
    };
    setScheduleSlots(newScheduleSlots);
  };

  const handleDateSchedule = async (e) => {
    e.preventDefault();

    if (!selectedDate) {
      toast.error("Please Select a Date", {
        style: {
          borderRadius: "10px",
          background: "#d53343",
          color: "#fff",
        },
      });
      setDayError(true);
      return;
    }

    let hasError = false;
    const newScheduleSlots = [...scheduleSlots];

    for (let i = 0; i < newScheduleSlots.length; i++) {
      const slot = newScheduleSlots[i];

      if (!slot.startTimeValue || !slot.endTimeValue) {
        toast.error("Please set both start and end times.");
        hasError = true;
        newScheduleSlots[i] = {
          ...slot,
          startTime: null,
          endTime: null,
          startTimeValue: null,
          endTimeValue: null,
        };
        break;
      }

      if (slot.endTimeValue.isBefore(slot.startTimeValue)) {
        toast.error("End time must be greater than start time.");
        hasError = true;
        newScheduleSlots[i] = {
          ...slot,
          startTime: null,
          endTime: null,
          startTimeValue: null,
          endTimeValue: null,
        };
        break;
      }

      if (formattedDate === moment().format("YYYY-MM-DD")) {
        if (slot.startTimeValue.isBefore(moment())) {
          toast.error(
            "Start time must be greater than the current time for today's date."
          );
          hasError = true;
          newScheduleSlots[i] = {
            ...slot,
            startTime: null,
            endTime: null,
            startTimeValue: null,
            endTimeValue: null,
          };
          break;
        }
      }
    }

    if (hasError) {
      setScheduleSlots(newScheduleSlots);
      return;
    }

    const id = params.id;
    const payload = {
      date: selectedDate,
      scheduleDetails: newScheduleSlots,
      therapistId,
    };

    await scheduleByDate(payload, {
      onSuccess: async (res) => {
        toast.success(res.message);
        await getDateScheduleData();
        setTimeout(() => {
          navigate("/schedule");
        }, 1500);
      },
      onFailed: (err) => {
        console.log(err.message);
        toast.error(err.message);
      },
    });
  };

  return (
    <>
      <form onSubmit={(e) => handleDateSchedule(e)}>
        {scheduleSlots.map((schedule, index) => {
          return (
            <div class="card inst_card schedule_card" key={index}>
              <h5> #{index + 1}</h5>
              <div class="row">
                <div class="col-lg-4 col-sm-4 col-md-4">
                  <div class="form-group">
                    <label for="">Therapy</label>
                    <span style={{ color: "red" }}> *</span>
                    <select
                      name="therapyId"
                      id=""
                      class="form-select"
                      value={initialValues.therapyId}
                      onChange={(e) => {
                        setScheduleSlots((preValue) =>
                          [...preValue].map((x) =>
                            x.therapyId === initialValues.therapyId
                              ? { ...x, therapyId: e.target.value }
                              : x
                          )
                        );
                      }}
                    >
                      <option value="0" selected>
                        Select Therapy
                      </option>
                      {programs.length > 0 &&
                        programs.map((therapy, index) => {
                          // console.log(therapy);
                          return (
                            <>
                              <option value={therapy._id}>
                                {therapy.therapyName}
                              </option>
                            </>
                          );
                        })}
                    </select>
                    {error && initialValues.therapyId === null ? (
                      <span id="err">Please Select a therapy</span>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div class="form-group mt-2">
                        <label for="">Price</label>
                        <input
                          type="text"
                          name=""
                          id=""
                          class="form-control details"
                          placeholder="Enter price details"
                          onChange={(e) => {
                            const value = e.target.value;
                            onchangePrice(index, value);
                          }}
                          value={initialValues.price}
                          required
                        />
                        {error && initialValues.price?.length === 0 ? (
                          <>
                            <span style={{ color: "red" }}>
                              Please add therapyname
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div> */}
                  <div class="form-group mt-2">
                    <label for="">Therapist</label>{" "}
                    <span style={{ color: "red" }}> *</span>
                    <select
                      name="therapyId"
                      id=""
                      class="form-select"
                      value={therapistId}
                      onChange={(e) => {
                        setTherapistId(e.target.value);
                      }}
                    >
                      <option value="0" selected>
                        Select Therapist
                      </option>
                      {therapists.length > 0 &&
                        therapists.map((therapist, index) => {
                          // console.log(therapy);
                          return (
                            <>
                              <option value={therapist._id}>
                                {therapist.firstName + " " + therapist.lastName}
                              </option>
                            </>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-4 col-md-4">
                  <div class="form-group ">
                    <label for="">Start Time</label>{" "}
                    <span style={{ color: "red" }}> *</span>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                      }}
                    >
                      <TimePicker
                        use12Hours
                        format="h:mm A"
                        onChange={(time, timeString) =>
                          onChangeTimePicker(
                            time,
                            timeString,
                            index,
                            "startTime"
                          )
                        }
                        defaultOpenValue={dayjs("00:00", "HH:mm")}
                        value={schedule.startTimeValue}
                        style={{
                          width: "100%",
                          borderColor: "transparent",
                          outline: "none",
                          boxShadow: "0 0 0 transparent",
                        }}
                        className="form-control"
                        placeholder="Select Start Time"
                      />
                    </Space>
                    {error &&
                      initialValues.startTime == null &&
                      initialValues.startTimeValue == null && (
                        <span id="err">Please Set the Start Time</span>
                      )}
                  </div>
                  <div class="form-group mt-2">
                    <label for="">End Time</label>{" "}
                    <span style={{ color: "red" }}> *</span>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                      }}
                    >
                      <TimePicker
                        use12Hours
                        format="h:mm A"
                        onChange={(time, timeString) =>
                          onChangeTimePicker(time, timeString, index, "endTime")
                        }
                        defaultOpenValue={dayjs("00:00", "HH:mm")}
                        value={schedule.endTimeValue}
                        style={{
                          width: "100%",
                          borderColor: "transparent",
                          outline: "none",
                          boxShadow: "0 0 0 transparent",
                        }}
                        className="form-control"
                      />
                    </Space>
                    {error &&
                      initialValues.endTime == null &&
                      initialValues.endTimeValue == null && (
                        <span id="err">Please Set the End Time</span>
                      )}
                  </div>
                </div>
                <div class="col-lg-4 col-sm-4 col-md-4">
                  <label for="">Description</label>
                  <textarea
                    className="form-control"
                    name=""
                    id=""
                    rows="4"
                    onChange={(e) => {
                      const value = e.target.value;
                      onChangeDescription(index, value);
                    }}
                    value={schedule.description}
                  ></textarea>
                </div>
              </div>
              {index !== 0 && scheduleSlots.length > 1 && (
                <button
                  class="delete_schedule"
                  onClick={() => deleteSchedule(index)}
                >
                  <Bs.BsFillTrashFill />
                </button>
              )}
            </div>
          );
        })}

        <button
          type="button"
          class="add_schedule"
          onClick={() => addNewSchedule()}
        >
          +
        </button>

        <button type="submit" className="form_btn mb-4">
          {scheduleLoading.action == "Add" && scheduleLoading.loading == true}
          Add
        </button>
      </form>
    </>
  );
}

export default DateScheduleByAdmin;
