import React, { useState, useEffect, useContext, Fragment } from "react";
import Header from "../../Components/Header/Header";
import { Table, Container } from "react-bootstrap";
import { FcCancel } from "react-icons/fc";
import { AppContext } from "../../context/AppContext";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import Utils from "../../Utils/Utils";
import Formatter from "../../Helper/Formatter";
import { MdCheck } from "react-icons/md";
import { IoReloadSharp } from "react-icons/io5";
import dayjs from "dayjs";
import { IoIosNotifications } from "react-icons/io";

import toast, { Toaster } from "react-hot-toast";
import Tab from "react-bootstrap/Tab";
import * as Ai from "react-icons/ai";
import { SlRefresh } from "react-icons/sl";
import Tabs from "react-bootstrap/Tabs";
import "./Purchases.css";
import { Modal, Space, TimePicker } from "antd";
import { BeatLoader } from "react-spinners";

function Sessions() {
  const {
    purchaseData,
    programs,
    getPurchaseList,
    cancelSession,
    therapists,
    registerdUsers,
    rescheduleSession,
    createSessionFromAdmin,
    scheduleLoading,
    cancelRefund,
    updatePaymentStatus,
  } = useContext(AppContext);
  const [expandedRows, setExpandedRows] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [scheduleLoad, setScheduleLoad] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [rescheduleData, setRescheduleData] = useState(null);
  const [addSession, setAddSession] = useState(false);
  const [reModal, setReModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [editedValues, setEditedValues] = useState({
    startTime: "",
    endTime: "",
    description: "",
    date: "",
    therapistId: "",
    dateSchedule: false,
  });
  const [session, setSession] = useState({
    startTime: "",
    endTime: "",
    date: "",
    therapistId: "",
    userId: "",
    therapyId: "",
  });
  const [payment, setPayment] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentIndex, setPaymentIndex] = useState(-1);
  useEffect(() => {
    setCurrentDate(Utils.formatDate(new Date()));
  }, []);

  const handleRowClick = (index) => {
    const newExpandedRows = [...expandedRows];
    for (let i = 0; i < newExpandedRows.length; i++) {
      if (i !== index) {
        newExpandedRows[i] = false;
      }
    }
    newExpandedRows[index] = !newExpandedRows[index];
    setExpandedRows(newExpandedRows);
    const selectedPurchase = purchaseData[index];
    const selectedTherapist = selectedPurchase?.therapist;
    const selectedTherapyDetails = selectedPurchase?.therapy;
    const bookingData = selectedPurchase.bookingDetails;
    setEditedValues({
      startTime: selectedPurchase?.startTime ?? "N/A",
      endTime: selectedPurchase?.endTime ?? "N/A",
      therapyId: selectedTherapyDetails?._id ?? "N/A",
      bookingId: selectedPurchase?.bookingParentId ?? "N/A",
      therapistId: selectedTherapist?._id ?? "N/A",
      date: selectedPurchase?.bookingDate ?? "N/A",
      slotId: selectedPurchase?.bookingId ?? "N/A",
      dateSchedule: bookingData?.dateSchedule ?? " ",
    });
  };

  const onChangeTimePicker = (time, timeString, type) => {
    switch (type) {
      case "startTime":
        setEditedValues((prev) => ({ ...prev, startTime: timeString }));
        return;
      case "endTime":
        setEditedValues((prev) => ({ ...prev, endTime: timeString }));
        return;
      default:
        return;
    }
  };
  const onCrateTime = (time, timeString, type) => {
    switch (type) {
      case "startTime":
        setSession((prev) => ({ ...prev, startTime: timeString }));
        return;

      case "endTime":
        setSession((prev) => ({ ...prev, endTime: timeString }));
        return;
      default:
        return;
    }
  };
  const onDateInputChange = (value) => {
    const dateConvert = new Date(value);
    if (!editedValues.date) return;
    setEditedValues((prevValues) => ({
      ...prevValues,
      date: dateConvert,
    }));
  };
  const onChangeSessionDate = (value) => {
    const dateConvert = new Date(value);
    setSession((prevValues) => ({
      ...prevValues,
      date: dateConvert,
    }));
  };

  const onChangeValue = (e) => {
    const { name, value } = e.target;

    if (name === "therapistId") {
      setEditedValues((prev) => ({
        ...prev,
        therapistId: value,
      }));
      if (name === "therapyId") {
        setEditedValues((prev) => ({
          ...prev,
          therapyId: value,
        }));
      }
    } else {
      setEditedValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onCreateSession = (e) => {
    const { name, value } = e.target;

    if (name === "therapistId") {
      setSession((prev) => ({
        ...prev,
        therapistId: value,
      }));
      if (name === "therapyId") {
        setSession((prev) => ({
          ...prev,
          therapyId: value,
        }));
      }
      if (name === "userId") {
        setSession((prev) => ({
          ...prev,
          userId: value,
        }));
      }
    } else {
      setSession((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleReschedule = async (e) => {
    e.preventDefault();
    setScheduleLoad(true);
    const payload = {
      bookingId: editedValues.bookingId,
      slotId: editedValues.slotId,
      therapistId: editedValues.therapistId,
      date: editedValues.date,
      startTime: editedValues.startTime,
      endTime: editedValues.endTime,
      dateSchedule: editedValues.dateSchedule,
    };
    await rescheduleSession(payload, {
      onSuccess: async (res) => {
        toast.success("Session rescheduled Successfully!");
        setIsModalOpen(false);
        setAddSession(false);
        setReModal(false);
        setScheduleLoad(false);
        await getPurchaseList();
      },
      onFailed: (err) => {
        toast.error(err.message);
        setScheduleLoad(false);
      },
    });
  };

  const handleModal = () => {
    setIsModalOpen(true);
    setReModal(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setReModal(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setReModal(false);
  };

  useEffect(() => {
    setExpandedRows([]);
  }, [activeTab]);

  const handleCancelSession = async (purchase) => {
    const payload = {
      therapistId: purchase.therapist._id,
      bookingId: purchase.bookingId,
      therapyId: purchase.therapy._id,
      cancelledBy: "Admin",
      cancelledDate: new Date(),
    };
    await cancelSession(payload, {
      onSuccess: async (res) => {
        toast.success("Session Cancelled Successfully!");
        window.location.reload();
      },
      onFailed: (err) => {
        toast.error(err.data);
      },
    });
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleRefund = async (purchase) => {
    if (!selectedOption) {
      toast.error("Please select a refund option");
      return;
    }

    const payload = {
      bookingId: purchase.bookingId,
      refundStatus: selectedOption,
    };

    await cancelRefund(payload, {
      onSuccess: async (res) => {
        toast.success(res.message);
        await getPurchaseList();
      },
      onFailed: (err) => {
        toast.error(err.data);
      },
    });
  };
  const handleShow = (purchase) => {
    setReModal(true);
    setRescheduleData(purchase);
  };

  const handleAddSession = () => {
    setAddSession(true);
  };

  const createSessionAdmin = async (e) => {
    e.preventDefault();
    if (
      session.therapistId == "" ||
      session.date == "" ||
      session.startTime == "" ||
      session.endTime == "" ||
      session.userId == "" ||
      session.therapyId == ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    const payload = {
      therapistId: session.therapistId,
      date: session.date,
      startTime: session.startTime,
      endTime: session.endTime,
      userId: session.userId,
      therapyId: session.therapyId,
      title: "Admin Requested",
      paymentStatus: "Not Received",
    };

    const startTime = Utils.convertStringToNumeric(session.startTime);
    const endTime = Utils.convertStringToNumeric(session.endTime);
    const newDate = Utils.formatDate(session.date);
    if (endTime <= startTime) {
      toast.error("End time must be greater than Start Time");
      return;
    }

    await createSessionFromAdmin(payload, {
      onSuccess: async (res) => {
        toast.success("Session Created Successfully!");
        await getPurchaseList();
        setTimeout(() => {
          setAddSession(false);
          setSession({
            date: "",
            startTime: "",
            endTime: "",
            therapistId: "",
            therapyId: "",
            userId: "",
          });
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  const UpdatePayment = async (purchase, index) => {
    setLoading(true);
    if (payment.length == 0) {
      toast.error("Please choose payment status!");
      return;
    }
    const payload = {
      bookingId: purchase.bookingId,
      paymentStatus: payment,
    };

    setPaymentIndex(index);
    // console.log(payload, "paylaod");
    // return;
    await updatePaymentStatus(payload, {
      onSuccess: async (res) => {
        toast.success("Payment Updated Successfully!");
        setLoading(false);
        await getPurchaseList();
      },
      onFailed: (err) => {
        toast.error(err.data);
        setLoading(false);
      },
    });
  };
  return (
    <>
      <Header />
      <Toaster position="top-center" />
      <main>
        <div className="container-fluid purchase-cont">
          <Modal
            open={addSession}
            onOk={() => setAddSession(false)}
            onCancel={() => setAddSession(false)}
          >
            <>
              <h6 className="mb-3 text-center">Create Session </h6>

              <form onSubmit={(e) => createSessionAdmin(e)}>
                <div className="row">
                  <div className="col">
                    <div class="form-group">
                      <label for="">User</label>
                      <span style={{ color: "red" }}> *</span>
                      <select
                        name="userId"
                        id=""
                        className="form-select"
                        value={session.userId}
                        onChange={(e) => onCreateSession(e)}
                      >
                        <option value="0" selected>
                          Select User
                        </option>
                        {registerdUsers.length > 0 &&
                          registerdUsers.map((user, index) => (
                            <option key={index} value={user._id}>
                              {user.name ?? "N/A"}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div class="form-group">
                      <label for="">Therapy</label>{" "}
                      <span style={{ color: "red" }}> *</span>
                      <select
                        name="therapyId"
                        id=""
                        className="form-select"
                        value={session.therapyId}
                        onChange={(e) => onCreateSession(e)}
                      >
                        <option value="0" selected>
                          Select Therapy
                        </option>
                        {programs.length > 0 &&
                          programs.map((pgm, index) => (
                            <option key={index} value={pgm._id}>
                              {pgm.therapyName ?? "N/A"}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group">
                    <label for="">Therapist</label>{" "}
                    <span style={{ color: "red" }}> *</span>
                    <select
                      name="therapistId"
                      id=""
                      className="form-select"
                      value={session.therapistId}
                      onChange={(e) => onCreateSession(e)}
                    >
                      <option value="0" selected>
                        Select Therapist
                      </option>
                      {therapists.length > 0 &&
                        therapists.map((therapist, index) => (
                          <option key={index} value={therapist._id}>
                            {therapist.firstName + " " + therapist.lastName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Date</label> <span style={{ color: "red" }}> *</span>
                    <input
                      type="date"
                      name="date"
                      className="form-control"
                      value={moment(session.date).format("YYYY-MM-DD")}
                      onChange={(e) => onChangeSessionDate(e.target.value)}
                      min={moment().format("YYYY-MM-DD")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div class="col form-group">
                    <label for="">Start Time</label>{" "}
                    <span style={{ color: "red" }}> *</span>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <TimePicker
                        use12Hours
                        format="h:mm A"
                        onChange={(time, timeString) =>
                          onCrateTime(time, timeString, "startTime")
                        }
                        style={{
                          width: "100%",
                          borderColor: "transparent",
                          outline: "none",
                          boxShadow: "0 0 0 transparent",
                        }}
                        className="form-control"
                        placeholder="Select Start Time"
                        value={
                          dayjs(session.startTime, "h:mm A").isValid()
                            ? dayjs(session.startTime, "h:mm A")
                            : null
                        }
                      />
                    </Space>
                  </div>

                  <div class="col form-group">
                    <label for="">End Time</label>{" "}
                    <span style={{ color: "red" }}> *</span>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                      }}
                    >
                      <TimePicker
                        use12Hours
                        format="h:mm A"
                        onChange={(time, timeString) =>
                          onCrateTime(time, timeString, "endTime")
                        }
                        style={{
                          width: "100%",
                          borderColor: "transparent",
                          outline: "none",
                          boxShadow: "0 0 0 transparent",
                        }}
                        className="form-control"
                        value={
                          dayjs(session.endTime, "h:mm A").isValid()
                            ? dayjs(session.endTime, "h:mm A")
                            : null
                        }
                      />
                    </Space>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <button
                    className="guest-btn mt-3 text-center"
                    disabled={
                      scheduleLoading.loading == true &&
                      scheduleLoading.action == "createAdmin"
                        ? true
                        : false
                    }
                  >
                    {scheduleLoading.loading == true &&
                    scheduleLoading.action == "createAdmin" ? (
                      <BeatLoader color="#fff" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </>
          </Modal>
          <section class="main">
            <button className="_add" onClick={() => handleAddSession()}>
              Add
            </button>
            <h2 className="text-center">Sessions</h2>

            <Modal
              open={reModal}
              onOk={() => setReModal(false)}
              onCancel={() => setReModal(false)}
            >
              {rescheduleData != null &&
                rescheduleData.reScheduleRequest.map((data, index) => {
                  return (
                    <>
                      <h6 className="mb-3">
                        Client requested a reschedule. Waiting for approval from
                        the instructor.
                      </h6>
                      <h6 className="mt-3 mb-3">
                        <b>Reschedule Details</b>
                      </h6>

                      <p>
                        Date: <b>{Utils.formatDate(data.date ?? "N/A")}</b>
                      </p>
                      <p>
                        Time :{" "}
                        <b>
                          {data.startTime ?? "00"}-{data.endTime ?? "00"}
                        </b>
                      </p>
                      <p>
                        Reason : <b>{data.reason ?? "Not Available"}</b>
                      </p>
                    </>
                  );
                })}
            </Modal>
            <div>
              <Modal
                title="Reschedule Session"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <form onSubmit={(e) => handleReschedule(e)}>
                  {" "}
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={moment(editedValues.date).format("YYYY-MM-DD")}
                          onChange={(e) => onDateInputChange(e.target.value)}
                          min={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                    {/* <div className="col">
                      <div class="form-group">
                        <label for="">Therapy</label>
                        <select
                          name="therapyId"
                          id=""
                          className="form-select"
                          value={editedValues.therapyId}
                          onChange={(e) => onChangeValue(e)}
                        >
                          <option value="0">Select Therapy</option>
                          {programs.length > 0 &&
                            programs.map((therapy, index) => (
                              <option key={index} value={therapy._id}>
                                {therapy.therapyName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col">
                      <div class="form-group">
                        <label for="">Therapist</label>
                        <select
                          name="therapistId"
                          id=""
                          className="form-select"
                          value={editedValues.therapistId}
                          onChange={(e) => onChangeValue(e)}
                        >
                          <option value="0" selected>
                            Select Therapist
                          </option>
                          {therapists.length > 0 &&
                            therapists.map((therapist, index) => (
                              <option key={index} value={therapist._id}>
                                {therapist.firstName + " " + therapist.lastName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <div class="form-group ">
                          <label for="">Start Time</label>
                          <Space
                            direction="vertical"
                            style={{
                              width: "100%",
                              borderColor: "transparent",
                            }}
                          >
                            <TimePicker
                              use12Hours
                              format="h:mm A"
                              value={
                                dayjs(
                                  editedValues.startTime,
                                  "h:mm A"
                                ).isValid()
                                  ? dayjs(editedValues.startTime, "h:mm A")
                                  : null
                              }
                              onChange={(time, timeString) =>
                                onChangeTimePicker(
                                  time,
                                  timeString,
                                  "startTime"
                                )
                              }
                              style={{
                                width: "100%",
                                borderColor: "transparent",
                                outline: "none",
                                boxShadow: "0 0 0 transparent",
                              }}
                              className="form-control"
                              placeholder="Select Start Time"
                            />
                          </Space>
                        </div>
                        <div className="">
                          <div class="form-group">
                            <label for="">End Time</label>
                            <Space
                              direction="vertical"
                              style={{
                                width: "100%",
                                borderColor: "transparent",
                              }}
                            >
                              <TimePicker
                                use12Hours
                                format="h:mm A"
                                value={
                                  dayjs(
                                    editedValues.endTime,
                                    "h:mm A"
                                  ).isValid()
                                    ? dayjs(editedValues.endTime, "h:mm A")
                                    : null
                                }
                                onChange={(time, timeString) =>
                                  onChangeTimePicker(
                                    time,
                                    timeString,
                                    "endTime"
                                  )
                                }
                                style={{
                                  width: "100%",
                                  borderColor: "transparent",
                                  outline: "none",
                                  boxShadow: "0 0 0 transparent",
                                }}
                                className="form-control"
                              />
                            </Space>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mx-auto text-center">
                    {scheduleLoad ? (
                      <button className="res-schedule-sub" disabled>
                        submiting...
                      </button>
                    ) : (
                      <button className="res-schedule-sub" type="submit">
                        submit
                      </button>
                    )}
                  </div>
                </form>
              </Modal>
            </div>
            <div>
              <div class="container">
                <button
                  type="button"
                  class="refresh_api"
                  onClick={async () => await getPurchaseList()}
                >
                  <i>
                    <SlRefresh />
                  </i>
                  <span>Refresh</span>
                </button>
              </div>
              <Tabs
                defaultActiveKey="All"
                id="justify-tab-example"
                className="mb-3 text-wrap"
                justify
                activeKey={activeTab}
                onSelect={(tab) => setActiveTab(tab)}
              >
                <Tab eventKey="All" title="All Sessions">
                  <div class="card user_card">
                    <Table className="show-table">
                      <table
                        role="table"
                        className="table table-bordered show-table"
                      >
                        <thead>
                          <tr>
                            {" "}
                            <th>#</th>
                            <th>Client Name</th>
                            <th>Email</th>
                            <th>Therapy</th>
                            <th>Therapist</th>
                            <th className="text-center">Date</th>
                            <th className="text-center">Time</th>
                            <th className="text-center">Session Status</th>
                            <th className="text-center">View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchaseData != null &&
                            purchaseData.length > 0 &&
                            purchaseData.toReversed().map((purchase, index) => {
                              const billingAddress = purchase.billingAddress;
                              const therapist = purchase?.therapist;
                              const therapyDetails = purchase?.therapy;
                              const bookingData = purchase?.bookingDetails;
                              const cancelledData =
                                bookingData.cancelInformation;
                              const userData = purchase?.userDetails;
                              const date = new Date(purchase.date);
                              const status = purchase.bookingDetails;

                              const hours = date
                                .getHours()
                                .toString()
                                .padStart(2, "0");
                              const minutes = date
                                .getMinutes()
                                .toString()
                                .padStart(2, "0");
                              const time = `${hours}:${minutes}`;

                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      {purchase?.userDetails?.name ?? "N/A"}
                                      {purchase.payment_method ==
                                        "Not Received" && (
                                        <span class="badge new pull-right ml-1">
                                          Payment Pending
                                        </span>
                                      )}

                                      {purchase.bookingDetails.streamStatus !=
                                        "STOPPED" &&
                                      purchase.bookingDetails.scheduleStatus ==
                                        "requested" &&
                                      purchase.bookingDetails.bookingStatus !=
                                        "CANCELED" &&
                                      purchase.bookingDetails.streamStatus !=
                                        "STARTED" ? (
                                        <i
                                          className="ml-3 red-ind"
                                          onClick={() => handleShow(purchase)}
                                        >
                                          <IoIosNotifications />
                                        </i>
                                      ) : (
                                        " "
                                      )}
                                    </td>
                                    <td>
                                      {purchase?.userDetails?.email ?? "N/A"}
                                    </td>
                                    <td>
                                      {therapyDetails?.therapyName ?? "N/A"}
                                    </td>
                                    <td>
                                      {therapist?.firstName + " " ?? "N/A"}
                                      {therapist?.lastName ?? "N/A"}
                                    </td>
                                    <td className="text-center">
                                      {Utils.formatDate(purchase.bookingDate)}
                                    </td>
                                    <td className="text-center">
                                      {purchase.startTime} - {purchase.endTime}
                                    </td>
                                    <td className="text-center">
                                      <Badge
                                        bg={
                                          purchase.bookingDetails
                                            .bookingStatus != "CANCELED"
                                            ? "success"
                                            : "danger"
                                        }
                                      >
                                        {purchase.bookingDetails
                                          .bookingStatus == "CANCELED"
                                          ? "Cancelled"
                                          : purchase.bookingDetails
                                              .streamStatus}
                                      </Badge>
                                    </td>

                                    <td
                                      className="text-center cur_po"
                                      onClick={() => handleRowClick(index)}
                                    >
                                      <Ai.AiFillEye />
                                    </td>
                                  </tr>
                                  {expandedRows[index] && (
                                    <>
                                      <tr>
                                        <td colSpan="9">
                                          <div className="table-view">
                                            <div className="expanded-content">
                                              <p className="col para-tab">
                                                <div className="badgesadded badge">
                                                  {purchase.rescheduled ==
                                                    true && "Rescheduled"}
                                                </div>
                                              </p>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Therapy Name :{" "}
                                                  {therapyDetails?.therapyName ??
                                                    "N/A"}
                                                </p>
                                                <p className="col para-tab">
                                                  Booking Id :{" "}
                                                  {purchase?.bookingId ?? "N/A"}
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Therapist Name :{" "}
                                                  {therapist?.firstName + " " ??
                                                    "N/A"}
                                                  {therapist?.lastName ?? "N/A"}
                                                </p>
                                                <p className="col para-tab">
                                                  Therapist Email :{" "}
                                                  {therapist?.email + " " ??
                                                    "N/A"}
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Slot Date :{" "}
                                                  {Formatter.formatDate(
                                                    purchase?.bookingDate
                                                  )}
                                                </p>
                                                <p className="col para-tab">
                                                  Time :{" "}
                                                  {/* {time != null
                                                  ? Formatter.formatTime(time)
                                                  : "N/A"} */}
                                                  {purchase.startTime} -
                                                  {purchase.endTime}
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Billing Date :{" "}
                                                  {Formatter.formatDate(
                                                    purchase?.date
                                                  )}
                                                </p>
                                                <p className="col para-tab">
                                                  Amount :{" "}
                                                  {purchase.amount ?? "N/A"}
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Billing Name :{" "}
                                                  {therapyDetails?.therapyName ??
                                                    "N/A"}
                                                </p>
                                                {bookingData?.paymentStatus ==
                                                  "Online" && (
                                                  <p className="col para-tab">
                                                    Billing Address:{" "}
                                                    {billingAddress.address +
                                                      " " ?? "N/A"}
                                                    {billingAddress.postcode +
                                                      " " ?? "N/A"}
                                                    {billingAddress.state +
                                                      " " ?? "N/A"}
                                                  </p>
                                                )}
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Client's Name :{" "}
                                                  {userData?.name ?? "N/A"}
                                                </p>
                                                <p className="col para-tab">
                                                  Client's Email :{" "}
                                                  {billingAddress?.email ??
                                                    "N/A"}
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  {bookingData.bookingStatus ==
                                                    "CANCELED" && (
                                                    <p className="canceled-session">
                                                      This session is Cancelled
                                                      by{" "}
                                                      {
                                                        cancelledData?.cancelledBy
                                                      }{" "}
                                                      on{" "}
                                                      {Utils.formatDate(
                                                        cancelledData?.cancelledDate
                                                      ) ?? "Date Not Availble"}
                                                      <br />
                                                    </p>
                                                  )}
                                                </p>
                                              </div>

                                              {purchase.payment_method !=
                                                null &&
                                                bookingData.bookingStatus !=
                                                  "CANCELED" && (
                                                  <div className="row">
                                                    <p className="col para-tab">
                                                      Payment Status :
                                                      <span class="badge new pull-right ml-3">
                                                        {
                                                          purchase.payment_method
                                                        }
                                                      </span>
                                                    </p>
                                                  </div>
                                                )}

                                              <br />
                                              {purchase.payment_method ==
                                                "Not Received" &&
                                                bookingData.bookingStatus !=
                                                  "CANCELED" && (
                                                  <Fragment>
                                                    <hr />
                                                    <p className="pt-2 pb-1 mb-0">
                                                      <strong>
                                                        <i>
                                                          Update Payement Status
                                                        </i>
                                                      </strong>
                                                    </p>
                                                    <div className="row">
                                                      <div className="col-md-6 d-flex para-tab">
                                                        <select
                                                          name=""
                                                          id=""
                                                          className="form-select"
                                                          onChange={(e) =>
                                                            setPayment(
                                                              e.target.value
                                                            )
                                                          }
                                                        >
                                                          <option
                                                            value=""
                                                            selected
                                                            disabled
                                                            hidden
                                                          >
                                                            Choose any payment
                                                            status
                                                          </option>
                                                          <option
                                                            value={
                                                              "Not Received"
                                                            }
                                                          >
                                                            Not Received
                                                          </option>
                                                          <option
                                                            value={"Online"}
                                                          >
                                                            Online
                                                          </option>
                                                          <option
                                                            value={"Offline"}
                                                          >
                                                            Offline
                                                          </option>
                                                        </select>{" "}
                                                        <button
                                                          type="button"
                                                          className="sel_sub"
                                                          onClick={() =>
                                                            UpdatePayment(
                                                              purchase,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          {loading
                                                            ? "...."
                                                            : "SUBMIT"}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </Fragment>
                                                )}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </Table>
                  </div>
                </Tab>
                <Tab eventKey="Upcoming" title="Upcoming">
                  <Table className="show-table">
                    <table
                      role="table"
                      className="table table-bordered show-table"
                    >
                      <thead>
                        <tr>
                          <th>Client Name</th>

                          <th>Email</th>
                          <th>Therapy</th>
                          <th>Therapist</th>
                          <th className="text-center">Date</th>
                          <th className="text-center">Time</th>
                          <th className="text-center">Reschedule</th>
                          <th className="text-center">Cancel</th>
                          <th className="text-center">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchaseData != null && purchaseData.length > 0 ? (
                          purchaseData.map((purchase, index) => {
                            const billingAddress = purchase.billingAddress;
                            const therapist = purchase?.therapist;
                            const therapyDetails = purchase?.therapy;
                            const date = new Date(purchase.date);
                            const status = purchase.bookingDetails;
                            const bookingData = purchase?.bookingDetails;
                            const reschedule = purchase.reScheduleRequest;
                            const userData = purchase?.userDetails;
                            const hours = date
                              .getHours()
                              .toString()
                              .padStart(2, "0");
                            const minutes = date
                              .getMinutes()
                              .toString()
                              .padStart(2, "0");
                            const time = `${hours}:${minutes}`;

                            return (
                              <React.Fragment key={index}>
                                {status != null &&
                                  status.streamStatus != null &&
                                  status.streamStatus == "UPCOMING" &&
                                  status.bookingStatus !== "CANCELED" && (
                                    <tr onClick={() => handleRowClick(index)}>
                                      <td>
                                        {purchase?.userDetails?.name ?? "N/A"}
                                      </td>
                                      <td>
                                        {purchase?.userDetails?.email ?? "N/A"}
                                      </td>
                                      <td>
                                        {therapyDetails?.therapyName ?? "N/A"}
                                      </td>
                                      <td>
                                        {therapist?.firstName + " " ?? "N/A"}
                                        {therapist?.lastName ?? "N/A"}
                                      </td>
                                      <td className="text-center">
                                        {Utils.formatDate(purchase.bookingDate)}
                                      </td>
                                      <td className="text-center">
                                        {purchase.startTime} -{" "}
                                        {purchase.endTime}
                                      </td>
                                      <td className="text-center">
                                        <i
                                          className="sch-butn"
                                          onClick={() => handleModal(purchase)}
                                        >
                                          <IoReloadSharp />
                                        </i>
                                      </td>
                                      <td
                                        className="text-center"
                                        onClick={() =>
                                          handleCancelSession(purchase)
                                        }
                                      >
                                        {status.bookingStatus === "CANCELED" ? (
                                          "Cancelled"
                                        ) : (
                                          <FcCancel />
                                        )}
                                      </td>
                                      <td className="text-center">
                                        <Ai.AiFillEye />
                                      </td>
                                    </tr>
                                  )}

                                {expandedRows[index] && (
                                  <>
                                    <tr>
                                      <td colSpan="6">
                                        <div className="table-view">
                                          <div className="expanded-content">
                                            <div className="row">
                                              <p className="col para-tab">
                                                Therapy Name :{" "}
                                                {therapyDetails?.therapyName ??
                                                  "N/A"}
                                              </p>
                                              <p className="col para-tab">
                                                Booking Id :{" "}
                                                {purchase?.bookingId ?? "N/A"}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                Therapist Name :{" "}
                                                {therapist?.firstName + " " ??
                                                  "N/A"}
                                                {therapist?.lastName ?? "N/A"}
                                              </p>
                                              <p className="col para-tab">
                                                Therapist Email :{" "}
                                                {therapist?.email + " " ??
                                                  "N/A"}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                Slot Date :{" "}
                                                {Formatter.formatDate(
                                                  purchase?.bookingDate
                                                )}
                                              </p>
                                              <p className="col para-tab">
                                                Time :{" "}
                                                {/* {time != null
                                                  ? Formatter.formatTime(time)
                                                  : "N/A"} */}
                                                {purchase.startTime} -
                                                {purchase.endTime}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                Billing Date :{" "}
                                                {Formatter.formatDate(
                                                  purchase?.date
                                                )}
                                              </p>
                                              <p className="col para-tab">
                                                Amount :{" "}
                                                {purchase.amount ?? "N/A"}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                Billing Name :{" "}
                                                {therapyDetails?.therapyName ??
                                                  "N/A"}
                                              </p>
                                              {bookingData?.paymentStatus ===
                                                "Online" && (
                                                <p className="col para-tab">
                                                  Billing Address:{" "}
                                                  {billingAddress.address +
                                                    " " ?? "N/A"}
                                                  {billingAddress.postcode +
                                                    " " ?? "N/A"}
                                                  {billingAddress.state + " " ??
                                                    "N/A"}
                                                </p>
                                              )}
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                Client's Name :{" "}
                                                {userData?.name ?? "N/A"}
                                              </p>
                                              <p className="col para-tab">
                                                Client's Email :{" "}
                                                {billingAddress?.email ?? "N/A"}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                <div className="badgesadded badge">
                                                  {purchase.rescheduled ==
                                                    true && "Rescheduled"}
                                                </div>
                                              </p>
                                            </div>
                                            <div>
                                              {status.scheduleStatus ===
                                                "requested" && (
                                                <>
                                                  <div className="row head-new">
                                                    Reschedule Request from
                                                    Client
                                                  </div>
                                                  {reschedule.map(
                                                    (change, index) => (
                                                      <>
                                                        <div
                                                          className="row"
                                                          key={index}
                                                        >
                                                          <p className="col para-tab">
                                                            Reschedule Date:{" "}
                                                            {change?.date ??
                                                              "N/A"}
                                                          </p>
                                                          <p className="col para-tab">
                                                            Time:{" "}
                                                            {change.startTime} -{" "}
                                                            {change.endTime}
                                                          </p>
                                                        </div>
                                                        <div className="row">
                                                          <p className="col para-tab">
                                                            Reason :{" "}
                                                            {change.reason ??
                                                              "N/A"}
                                                          </p>
                                                        </div>
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              )}
                                            </div>
                                            {purchase.payment_method != null &&
                                              bookingData.bookingStatus !=
                                                "CANCELED" && (
                                                <div className="row">
                                                  <p className="col para-tab">
                                                    Payment Status :
                                                    <span class="badge new pull-right ml-3">
                                                      {purchase.payment_method}
                                                    </span>
                                                  </p>
                                                </div>
                                              )}

                                            <br />
                                            {purchase.payment_method ==
                                              "Not Received" &&
                                              bookingData.bookingStatus !=
                                                "CANCELED" && (
                                                <Fragment>
                                                  <hr />
                                                  <p className="pt-2 pb-1 mb-0">
                                                    <strong>
                                                      <i>
                                                        Update Payement Status
                                                      </i>
                                                    </strong>
                                                  </p>
                                                  <div className="row">
                                                    <div className="col-md-6 d-flex para-tab">
                                                      <select
                                                        name=""
                                                        id=""
                                                        className="form-select"
                                                        onChange={(e) =>
                                                          setPayment(
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option
                                                          value=""
                                                          selected
                                                          disabled
                                                          hidden
                                                        >
                                                          Choose any payment
                                                          status
                                                        </option>
                                                        <option
                                                          value={"Not Received"}
                                                        >
                                                          Not Received
                                                        </option>
                                                        <option
                                                          value={"Online"}
                                                        >
                                                          Online
                                                        </option>
                                                        <option
                                                          value={"Offline"}
                                                        >
                                                          Offline
                                                        </option>
                                                      </select>{" "}
                                                      <button
                                                        type="button"
                                                        className="sel_sub"
                                                        onClick={() =>
                                                          UpdatePayment(
                                                            purchase,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        {loading
                                                          ? "...."
                                                          : "SUBMIT"}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </Fragment>
                                              )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="6">NO DATA AVAILABLE</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Table>
                </Tab>
                <Tab eventKey="Finished" title="Finished" className="fin-tab">
                  <Table className="show-table">
                    <table
                      role="table"
                      className="table table-bordered show-table"
                    >
                      <thead>
                        <tr>
                          <th>Client Name</th>
                          <th>Email</th>
                          <th>Therapy</th>
                          <th>Therapist</th>
                          <th className="text-center">Date</th>
                          <th className="text-center">Time</th>
                          <th className="text-center">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchaseData != null && purchaseData.length > 0 ? (
                          purchaseData.map((purchase, index) => {
                            const billingAddress = purchase.billingAddress;
                            const therapist = purchase?.therapist;
                            const therapyDetails = purchase?.therapy;
                            const date = new Date(purchase.date);
                            const bookingData = purchase?.bookingDetails;
                            const userData = purchase?.userDetails;
                            const hours = date
                              .getHours()
                              .toString()
                              .padStart(2, "0");
                            const minutes = date
                              .getMinutes()
                              .toString()
                              .padStart(2, "0");
                            const time = `${hours}:${minutes}`;

                            return (
                              <React.Fragment key={index}>
                                {purchase.bookingDetails != null &&
                                  purchase.bookingDetails.streamStatus !=
                                    null &&
                                  purchase.bookingDetails.streamStatus ==
                                    "STOPPED" && (
                                    <tr onClick={() => handleRowClick(index)}>
                                      <td>
                                        {purchase?.userDetails?.name ?? "N/A"}
                                      </td>
                                      <td>
                                        {purchase?.userDetails?.email ?? "N/A"}
                                      </td>
                                      <td>
                                        {therapyDetails?.therapyName ?? "N/A"}
                                      </td>
                                      <td>
                                        {therapist?.firstName + " " ?? "N/A"}
                                        {therapist?.lastName ?? "N/A"}
                                      </td>
                                      <td className="text-center">
                                        {Utils.formatDate(purchase.bookingDate)}
                                      </td>
                                      <td className="text-center">
                                        {purchase.startTime} -{" "}
                                        {purchase.endTime}
                                      </td>
                                      <td className="text-center">
                                        <Ai.AiFillEye />
                                      </td>
                                    </tr>
                                  )}

                                {expandedRows[index] && (
                                  <>
                                    <tr>
                                      <td colSpan="6">
                                        <div className="table-view">
                                          <div className="expanded-content">
                                            <div className="row">
                                              <p className="col para-tab">
                                                Therapy Name :{" "}
                                                {therapyDetails?.therapyName ??
                                                  "N/A"}
                                              </p>
                                              <p className="col para-tab">
                                                Booking Id :{" "}
                                                {purchase?.bookingId ?? "N/A"}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                Therapist Name :{" "}
                                                {therapist?.firstName + " " ??
                                                  "N/A"}
                                                {therapist?.lastName ?? "N/A"}
                                              </p>
                                              <p className="col para-tab">
                                                Therapist Email :{" "}
                                                {therapist?.email + " " ??
                                                  "N/A"}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                Slot Date :{" "}
                                                {Formatter.formatDate(
                                                  purchase?.bookingDate
                                                )}
                                              </p>
                                              <p className="col para-tab">
                                                Time :{" "}
                                                {/* {time != null
                                                  ? Formatter.formatTime(time)
                                                  : "N/A"} */}
                                                {purchase.startTime} -
                                                {purchase.endTime}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                Billing Date :{" "}
                                                {Formatter.formatDate(
                                                  purchase?.date
                                                )}
                                              </p>
                                              <p className="col para-tab">
                                                Amount :{" "}
                                                {purchase.amount ?? "N/A"}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                Billing Name :{" "}
                                                {therapyDetails?.therapyName ??
                                                  "N/A"}
                                              </p>
                                              {bookingData?.paymentStatus ===
                                                "Online" && (
                                                <p className="col para-tab">
                                                  Billing Address:{" "}
                                                  {billingAddress.address +
                                                    " " ?? "N/A"}
                                                  {billingAddress.postcode +
                                                    " " ?? "N/A"}
                                                  {billingAddress.state + " " ??
                                                    "N/A"}
                                                </p>
                                              )}
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                Client's Name :{" "}
                                                {userData?.name ?? "N/A"}
                                              </p>
                                              <p className="col para-tab">
                                                Client's Email :{" "}
                                                {billingAddress?.email ?? "N/A"}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="col para-tab">
                                                <div className="badgesadded badge">
                                                  {purchase.rescheduled ==
                                                    true && "Rescheduled"}
                                                </div>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="6">NO DATA AVAILABLE</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Table>
                </Tab>
                <Tab
                  eventKey="Cancelled"
                  title="Cancelled"
                  defaultActiveKey="All"
                  id="justify-tab-example"
                  className="mb-3 text-wrap"
                  justify
                >
                  <Table className="show-table">
                    <table
                      role="table"
                      className="table table-bordered show-table"
                    >
                      <thead>
                        <tr>
                          <th>Client Name</th>

                          <th>Therapy</th>
                          <th>Therapist</th>
                          <th className="text-center">Date</th>
                          <th className="text-center">Time</th>
                          <th className="text-center">Refund Status</th>
                          <th className="text-center">View</th>
                          <th className="text-center">Refund</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchaseData != null && purchaseData.length > 0 ? (
                          purchaseData.map((purchase, index) => {
                            const billingAddress = purchase.billingAddress;
                            const therapist = purchase?.therapist;
                            const therapyDetails = purchase?.therapy;
                            const date = new Date(purchase.date);
                            const bookingData = purchase?.bookingDetails;
                            const cancelledData = bookingData.cancelInformation;
                            const userData = purchase?.userDetails;
                            const hours = date
                              .getHours()
                              .toString()
                              .padStart(2, "0");
                            const minutes = date
                              .getMinutes()
                              .toString()
                              .padStart(2, "0");

                            return (
                              <React.Fragment key={index}>
                                {purchase.bookingDetails.streamStatus != null &&
                                  purchase.bookingDetails.bookingStatus ==
                                    "CANCELED" && (
                                    <tr onClick={() => handleRowClick(index)}>
                                      <td>
                                        {purchase?.userDetails?.name ?? "N/A"}
                                      </td>

                                      <td>
                                        {therapyDetails?.therapyName ?? "N/A"}
                                      </td>
                                      <td>
                                        {therapist?.firstName + " " ?? "N/A"}
                                        {therapist?.lastName ?? "N/A"}
                                      </td>
                                      <td className="text-center">
                                        {Utils.formatDate(purchase.bookingDate)}
                                      </td>
                                      <td className="text-center">
                                        {purchase.startTime} -{" "}
                                        {purchase.endTime}
                                      </td>
                                      <td className="text-center">
                                        {bookingData.refundStatus ==
                                          "completed" && (
                                          <Badge bg="success">
                                            {bookingData.refundStatus}
                                          </Badge>
                                        )}
                                        {bookingData.refundStatus == "None" && (
                                          <Badge bg="danger">
                                            Not Initiated
                                          </Badge>
                                        )}
                                        {bookingData.refundStatus ==
                                          "Initiated" && (
                                          <Badge bg="secondary">
                                            {bookingData.refundStatus}
                                          </Badge>
                                        )}
                                      </td>
                                      <td className="text-center">
                                        <Ai.AiFillEye />
                                      </td>
                                      <td className="text-center">
                                        {bookingData.refundStatus ==
                                          "completed" &&
                                        purchase.payment_method !=
                                          "Not Received" ? (
                                          <p className="grnn"> </p>
                                        ) : (
                                          <div>
                                            <select
                                              onChange={(e) =>
                                                handleOptionClick(
                                                  e.target.value
                                                )
                                              }
                                              className="formSelect"
                                            >
                                              <option
                                                value="0"
                                                selected
                                                disabled
                                              >
                                                Select an option
                                              </option>
                                              <option value="Initiated">
                                                Initiated
                                              </option>
                                              <option value="completed">
                                                Completed
                                              </option>
                                            </select>
                                            <button
                                              className="complete"
                                              onClick={() =>
                                                handleRefund(purchase)
                                              }
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                <>
                                  {expandedRows[index] && (
                                    <>
                                      <tr>
                                        <td colSpan="6">
                                          <div className="table-view">
                                            <div className="expanded-content">
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Therapy Name :{" "}
                                                  {therapyDetails?.therapyName ??
                                                    "N/A"}
                                                </p>
                                                <p className="col para-tab">
                                                  Booking Id :{" "}
                                                  {purchase?.bookingId ?? "N/A"}
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Therapist Name :{" "}
                                                  {therapist?.firstName + " " ??
                                                    "N/A"}
                                                  {therapist?.lastName ?? "N/A"}
                                                </p>
                                                <p className="col para-tab">
                                                  Therapist Email :{" "}
                                                  {therapist?.email + " " ??
                                                    "N/A"}
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Slot Date :{" "}
                                                  {Formatter.formatDate(
                                                    purchase?.bookingDate
                                                  )}
                                                </p>
                                                <p className="col para-tab">
                                                  Time :{" "}
                                                  {/* {time != null
                                                  ? Formatter.formatTime(time)
                                                  : "N/A"} */}
                                                  {purchase.startTime} -
                                                  {purchase.endTime}
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Billing Date :{" "}
                                                  {Formatter.formatDate(
                                                    purchase?.date
                                                  )}
                                                </p>
                                                <p className="col para-tab">
                                                  Amount :{" "}
                                                  {purchase.amount ?? "N/A"}
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Billing Name :{" "}
                                                  {therapyDetails?.therapyName ??
                                                    "N/A"}
                                                </p>
                                                {/* {bookingData?.paymentStatus ==
                                                  "Online" && (
                                                  <p className="col para-tab">
                                                    Billing Address:{" "}
                                                    {billingAddress.address +
                                                      " " ?? " "}
                                                    {billingAddress.postcode +
                                                      " " ?? " "}
                                                    {billingAddress.state +
                                                      " " ?? " "}
                                                  </p>
                                                )} */}
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  Client's Name :{" "}
                                                  {userData?.name ?? "N/A"}
                                                </p>
                                                <p className="col para-tab">
                                                  Client's Email :{" "}
                                                  {billingAddress?.email ??
                                                    "N/A"}
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  <div className="badgesadded badge">
                                                    {purchase.rescheduled ==
                                                      true && "Rescheduled"}
                                                  </div>
                                                </p>
                                                <p className="col para-tab">
                                                  <div>
                                                    {bookingData.refundStatus ==
                                                    "None" ? (
                                                      <div className="d-flex">
                                                        Refund Status :{" "}
                                                        <p className="para-red">
                                                          Not Initiated
                                                        </p>
                                                      </div>
                                                    ) : (
                                                      <>
                                                        Refund Status :{" "}
                                                        <p className="badgesadded badge">
                                                          {
                                                            bookingData.refundStatus
                                                          }
                                                        </p>
                                                      </>
                                                    )}
                                                  </div>
                                                </p>
                                              </div>
                                              <div className="row">
                                                <p className="col para-tab">
                                                  <p className="col para-tab">
                                                    {bookingData.bookingStatus ==
                                                      "CANCELED" && (
                                                      <p className="canceled-session">
                                                        This session is
                                                        Cancelled by{" "}
                                                        {
                                                          cancelledData?.cancelledBy
                                                        }{" "}
                                                        on{" "}
                                                        {Utils.formatDate(
                                                          cancelledData?.cancelledDate
                                                        ) ??
                                                          "Date Not Availble"}
                                                        <br />
                                                      </p>
                                                    )}
                                                  </p>
                                                </p>
                                                {purchase.payment_method !=
                                                  "Not Received" && (
                                                  <p className="col para-tab">
                                                    Refund Amount :{" "}
                                                    {purchase.amount ?? "N/A"}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <td colSpan="6">NO DATA AVAILABLE</td>
                        )}
                      </tbody>
                    </table>
                  </Table>
                </Tab>
              </Tabs>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default Sessions;
